<template>
	<div class="tabs">
		<div class="tabs-top">
			<span :class="`${activeName === 'sign' ? 'active' : ''}`" @click="activeName = 'sign'">签到信息</span>
			<span :class="`${activeName === 'plan' ? 'active' : ''}`" @click="activeName = 'plan'">行车计划</span>
			<span :class="`${activeName === 'task' ? 'active' : ''}`" @click="activeName = 'task'">调度任务</span>
		</div>
		<div class="tabs-content">
			<div class="sign" v-show="activeName === 'sign'">
				<div class="top">
					<div>刷卡</div>
					<div>
						签到状态：{{
							info.type == 1 ? "上班" : info.type == 2 ? "下班" : info.type == 3 ? "签到" : info.type == 4 ? "签到" : "例检"
						}}
					</div>
				</div>
				<ul>
					<li>
						<div>线路：{{ info.routeName }}</div>
						<div>车辆编号：{{ info.customNo }}</div>
					</li>
					<li>
						<div>车牌：{{ info.vehicleNo }}</div>
						<div>最新刷卡时间：{{ info.newTime }}</div>
					</li>
					<li>
						<div>签到地点：{{ info.place }}</div>
						<div></div>
					</li>
				</ul>
			</div>
			<div v-show="activeName === 'plan'">
				<jy-table ref="jyTable" class="driver-task-table" :data="info.plan" max-height="150">
					<jy-table-column prop="driverName" label="计划司机名称"></jy-table-column>
					<jy-table-column prop="planTime" label="计划发车时间"></jy-table-column>
					<jy-table-column prop="planType" label="方案类型">
						<template slot-scope="scope">
							{{ scope.row.planType == 1 ? "上行" : "下行" }}
						</template></jy-table-column
					>
					<jy-table-column prop="routeName" label="线路名称"></jy-table-column>
				</jy-table>
			</div>
			<div v-show="activeName === 'task'">
				<jy-table ref="jyTable" class="driver-task-table" :data="info.scheduling" max-height="150">
					<jy-table-column prop="planDriver" label="计划司机名称"></jy-table-column>
					<jy-table-column prop="planTime" label="计划发车时间"></jy-table-column>
					<jy-table-column prop="planType" label="方案类型">
						<template slot-scope="scope">
							{{ scope.row.planType == 1 ? "上行" : "下行" }}
						</template></jy-table-column
					>
					<jy-table-column prop="routeNa" label="线路名称"></jy-table-column>
				</jy-table>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		info: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	components: {},
	name: "taskInfo",

	mounted() {},
	data() {
		return {
			planList: [],
			activeName: "sign"
		};
	},
	methods: {}
};
</script>
<style lang="scss" scoped>
.tabs {
	padding: 10px 0;
	&-top {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		padding-bottom: 13px;
		span {
			display: inline-block;
			cursor: pointer;
			padding: 0 12px;
			&.active {
				border-bottom: 3px solid #2a73ff;
				padding-bottom: 13px;
				margin-bottom: -13px;
			}
		}
	}
	&-content {
		padding: 13px 0;
	}
}
.sign {
	background: rgba(255, 255, 255, 0.05);
	padding: 20px;
	max-width: 441px;
	.top {
		display: flex;
		padding: 9px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		margin-bottom: 12px;
		& > div {
			flex: 1;
		}
	}
	li {
		display: flex;
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
		& > div {
			flex: 1;
		}
	}
}
</style>
<style lang="scss">
.driver-task-table {
	.el-table {
		font-size: 12px;
	}
}
</style>
