<template>
	<div>
		<div :class="`masker ${closed ? '' : 'show'}`" @click="close"></div>
		<div :class="`dialog-wrap ${closed ? '' : 'show'}`" ref="dialogContent">
			<div class="dialog-container">
				<div class="dialog-container-title">
					<div>{{ title }}</div>
					<div><i class="el-icon-close" @click="close"></i></div>
				</div>
				<div style="max-height: 600px; overflow: auto">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "stationInfo",
	props: { title: { required: true, default: "" }, show: { require: true, type: Boolean, default: false } },
	data() {
		return { closed: true };
	},
	watch: {
		show(val) {
			console.log(val, "---是否关闭");
			this.closed = !val;
			this.$nextTick(() => {
				this.$refs.dialogContent.style.left = `calc(50% - ${this.$refs.dialogContent.clientWidth / 2}px)`;
				this.$refs.dialogContent.style.top = `calc(50% - ${this.$refs.dialogContent.clientHeight / 2}px)`;
			});
		}
	},
	mounted() {},
	methods: {
		close() {
			console.log("close");
			this.closed = true;
			this.$emit("update:show", false);
		}
	}
};
</script>

<style lang="scss" scoped>
.masker {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 998;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	display: none;
	&.show {
		display: flex;
	}
}
.dialog-wrap {
	position: fixed;
	display: none;
	&.show {
		display: flex;
	}
	z-index: 999;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	.dialog-container {
		// width: 800px;
		color: #ffffff;
		background: #2d4674;
		padding-bottom: 25px;
		&-title {
			display: flex;
			height: 56px;
			font-size: 16px;
			background: #283e68;
			box-sizing: border-box;
			padding: 16px 35px;
			& > div {
				flex: 1;
				&:last-child {
					text-align: right;
				}
				i {
					cursor: pointer;
				}
			}
		}
	}
}
</style>
