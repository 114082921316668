<template>
	<div ref="map" id="bdMapWrap" class="ele-map"></div>
</template>
<script>
import Station from "@/views/admin/bigScreenNew/line/station";
let serviceMap = null;
export default {
	name: "EleMap",
	props: {
		coordinate: {
			required: true,
			type: Object,
			default: () => {
				return {};
			}
		},
		routeId: {
			type: String,
			default: ""
		},
		showDialog: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		showDialog: {
			async handler(val) {
				await this.$nextTick();
				val && this.initMap();
			},
			immediate: true
		}
	},
	/* mounted() {
		this.initMap();
	}, */
	methods: {
		initMap() {
			serviceMap = new BMapGL.Map("bdMapWrap");
			serviceMap.centerAndZoom(new BMapGL.Point(89.192671, 42.95001), 18);
			serviceMap.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
			serviceMap.setTrafficOn();
			this.getLine();
		},
		getLine() {
			const url = "/crrc-palm-bus/gis/line/lineMap";
			const routeId = this.routeId;
			if (routeId) {
				this.$http.post(url, { routeId }, { baseType: "base" }).then(res => {
					res.map(item => {
						const { siteVoList } = item;
						const drivingItem = new BMapGL.DrivingRoute(serviceMap);
						const siteList = siteVoList.map((site, index) => {
							const p = gcoord.transform([site.longitude, site.latitude], gcoord.WGS84, gcoord.BD09);
							const stationPoint = new BMapGL.Point(p[0], p[1]);
							let type = "";
							if (index === 0) {
								type = "first";
							} else if (index === siteVoList.length - 1) {
								type = "last";
							}
							const station = new Station(stationPoint, { type, txt: site.siteName, index });
							serviceMap?.addOverlay(station);
							return stationPoint;
						});
						for (let i = 0; i < siteList.length; i++) {
							if (i !== siteList.length - 1) {
								drivingItem?.search(siteList[i], siteList[i + 1]); // 绘制两个点之间的路线
							}
						}
						drivingItem?.setSearchCompleteCallback(() => {
							const pts = drivingItem?.getResults().getPlan(0).getRoute(0).getPath();
							const polyline = new BMapGL.Polyline(pts, {
								strokeColor: "#048CFF",
								strokeWeight: 5,
								strokeOpacity: 1
							});
							serviceMap?.addOverlay(polyline);
						});
						serviceMap?.setViewport(siteList);
					});
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.ele-map {
	width: 100%;
	height: 500px;
	position: relative;
	::v-deep {
		.station_wrap {
			&:nth-child(2n + 1) {
				flex-direction: row-reverse;
				text-align: right;
			}
			.icon {
				width: 27px;
				height: 44px;
				background: url("./images/station@2x.png") no-repeat 0 0 / 100% 100% transparent;
				&.first {
					background: url("./images/start@2x.png") no-repeat 0 0 / 100% 100% transparent;
				}
				&.last {
					background: url("./images/end@2x.png") no-repeat 0 0 / 100% 100% transparent;
				}
				& + div {
					color: #000000;
					font-size: 14px;
					margin-left: 10px;
					margin-top: 4px;
					width: 100px;
					height: 22px;
					border-radius: 0 22px 22px 0;
				}
			}
		}
	}
}
</style>
