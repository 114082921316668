<template>
	<div class="schdule-plan-card">
		<div class="card-title">{{ titleText }}{{ `（${dataList.length}）` }}</div>
		<div class="data-list">
			<div class="table-list">
				<div class="t-head">
					<span class="per-1">车辆</span>
					<span class="per-2">自编号</span>
					<span class="per-3">司机姓名</span>
					<span class="per-4">发车时间</span>
				</div>
				<div class="t-body">
					<div class="data-item" v-for="(item, index) in dataList" :key="index">
						<div class="per-1" @click="openCarInfo(item)">
							<span class="icon"></span>
						</div>
						<div class="per-2" @click="openCarInfo(item)">
							<span>{{ item.customNo }}</span>
						</div>
						<div class="per-3" @click="openDriverInfo(item)">
							<span>{{ item.driverNa || "- -" }}</span>
						</div>
						<!-- <div class="per-3">
							{{ item.driverNa }}
						</div> -->
						<div class="per-4 time-text">
							{{ item.planTime }}
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="list-item" v-for="(item, index) in dataList" :key="index">
				<div class="data-item" :class="!item.planTime ? 'no-data' : ''">
					<span>{{ item.planTime || "- -" }}</span>
				</div>
				<el-tooltip effect="dark" :content="item.driverNa || '- -'" placement="top">
					<div class="data-item driver" @click="openDriverInfo(item)" :class="!item.driverNa ? 'no-data' : ''">
						<span>{{ item.driverNa || "- -" }}</span>
					</div>
				</el-tooltip>
				<el-tooltip effect="dark" :content="item.vehicleNo || '- -'" placement="top-start">
					<div class="data-item driver" @click="openCarInfo(item)" :class="!item.vehicleNo ? 'no-data' : ''">
						<span>{{ item.vehicleNo || "- -" }}</span>
					</div>
				</el-tooltip>
			</div> -->
		</div>
		<!-- <div class="show-card-btn" v-show="!isAllLine" @click="handleShowTable">{{ showTable ? "隐藏看板" : "显示看板" }}</div> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			showTable: false,
			dataList: []
		};
	},
	props: {
		goType: {
			type: [String, Number],
			default: 1
		},
		lineInfo: {
			type: Object,
			default: () => ({})
		},
		// 在场展示
		showOn: {
			type: Boolean,
			default: true
		},
		// 在途展示
		showIn: {
			type: Boolean,
			default: true
		},
		isAllLine: {
			type: Boolean,
			default: false
		},
		list: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		isUp() {
			return this.goType == 1;
		},
		showFlag() {
			return String(this.showOn) + String(this.showIn);
		},
		titleText() {
			if (this.isUp) {
				return `上行：${this.lineInfo?.upSites ? this.lineInfo?.upSites[0]?.siteName : ""}`;
			} else {
				return `下行：${this.lineInfo?.downSites ? this.lineInfo?.downSites[0]?.siteName : ""}`;
			}
		}
	},
	watch: {
		/* lineInfo(obj) {
			obj.routeId && this.init();
		}, */
		list(list) {
			list && this.init();
		},
		// 监听子表格是否两个都关闭了，修改状态，关闭外层包裹
		showFlag() {
			if (!this.showIn && !this.showOn) {
				this.showTable = false;
				this.$emit("toggle-handle", this.showTable);
			}
		}
	},
	methods: {
		init() {
			this.dataList = this.isUp ? this.lineInfo.upWaybillList || [] : this.lineInfo.downWaybillList || [];
			/* const url = `/crrc-palm-bus/realtimeOperation/getRouteWaybillPlan`;
			const params = { routeId: this.lineInfo.routeId, planType: this.goType };
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.dataList = res || [];
			}); */
		},
		handleShowTable() {
			this.showTable = !this.showTable;
			this.$emit("toggle-handle", this.showTable);
		},
		openDriverInfo(item) {
			this.$emit("showDriverInfo", item);
		},
		openCarInfo(item) {
			this.$emit("showCarInfo", { ...item, routeId: this.lineInfo.routeId });
		}
	}
};
</script>

<style lang="scss" scoped>
.schdule-plan-card {
	width: 100%;
	padding-bottom: 16px;
	margin-bottom: 8px;
	// padding: 20px 0 30px;
	background: rgba(35, 60, 115, 0.4);
	border-radius: 4px;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	.card-title {
		width: 100%;
		height: 38px;
		line-height: 38px;
		font-size: 14px;
		font-weight: 600;
		background: linear-gradient(270deg, #2864cd 0%, #0582eb 100%);
		padding-left: 17px;
		border-radius: 4px 4px 0 0;
		color: #ffffff;
	}
	.data-list {
		// height: 220px;
		// overflow-y: auto;
		// padding: 0 16px;
		.table-list {
			width: 100%;
			font-size: 12px;
			color: #fff;
			.t-head {
				display: flex;
				align-items: center;
				line-height: 34px;
				font-weight: 600;
				padding-right: 6px;
			}
			.per-1 {
				width: 15%;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.icon {
					width: 28px;
					height: 15px;
					background: url("~@/assets/homeNew/index/normal_car_icon.png") no-repeat;
					background-size: 100% 100%;
				}
			}
			.per-2 {
				width: 25%;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				cursor: pointer;
			}
			.per-3 {
				width: 35%;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				padding: 0 5px;
				white-space: nowrap;
				cursor: pointer;
			}
			.per-4 {
				text-align: center;
				width: 25%;
			}
			.t-body {
				height: 136px;
				overflow: auto;
				.data-item {
					display: flex;
					align-items: center;
					line-height: 34px;
					.time-text {
						color: #50abf5;
					}
				}
			}
		}
		/* .list-item {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
			.data-item {
				width: 64px;
				height: 30px;
				text-align: center;
				background: #2a73ff;
				border-radius: 4px;
				font-size: 14px;
				color: #ffffff;
				line-height: 30px;
				margin-right: 8px;
				padding: 0 10px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&.no-data {
					background: #233c73;
					color: #cccccc;
				}
				&.driver {
					cursor: pointer;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		} */
	}
	/* .show-card-btn {
		margin-top: 36px;
		width: 96px;
		height: 36px;
		border-radius: 4px;
		border: 1px solid #2a73ff;
		font-size: 14px;
		color: #ffffff;
		line-height: 20px;
		text-align: center;
		line-height: 36px;
		cursor: pointer;
	} */
}
</style>
