export default function createMap(elementId = "bMap", bMapStyleJson) {
	const { BMapGL } = window;
	let BMap = new BMapGL.Map(elementId, { minZoom: 10, maxZoom: 18 });
	BMap.centerAndZoom(new BMapGL.Point(89.192671, 42.95001), 18); // 初始化地图,设置中心点坐标和地图级别
	BMap.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
	BMap.setHeading(15);
	BMap.setTilt(15);
	BMap.setMapStyleV2({ styleJson: bMapStyleJson.bMapJson });
	return BMap;
}
