<template>
	<div class="car-list home-page-new-container">
		<div class="filter">
			<el-form ref="form" :inline="true" size="small">
				<el-form-item label="车牌编号：">
					<el-input placeholder="请输入" v-model="formData.customNo" />
				</el-form-item>
				<el-form-item label="车牌号：">
					<el-input placeholder="请输入" v-model="formData.vehicleNo" />
				</el-form-item>
				<el-form-item label="线路：">
					<el-select v-model="formData.routeId">
						<el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.routeId"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="init">搜索</el-button>
					<el-button @click="reset">清除</el-button>
				</el-form-item>
			</el-form>
		</div>
		<jy-table max-height="595" ref="jyTable" :data="dataList">
			<jy-table-column prop="dTime" label="计划发车" min-width="80" fixed="left"></jy-table-column>
			<jy-table-column prop="routeNa" label="运行线路" min-width="100" fixed="left"></jy-table-column>
			<jy-table-column prop="customNo" label="车号" min-width="100" fixed="left"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌" min-width="100" fixed="left"></jy-table-column>
			<jy-table-column prop="driverNa" label="排班司机" min-width="100" fixed="left"></jy-table-column>
			<jy-table-column prop="chenwu" label="乘务" min-width="80" fixed="left"></jy-table-column>
			<jy-table-column prop="customNo" label="车辆状态" min-width="80" fixed="left">
				<template v-slot="{ row }">
					{{ statusMap[row.eState] || "-" }}
				</template>
			</jy-table-column>
			<!-- <jy-table-column prop="customNo" label="评分" min-width="100" fixed="left"></jy-table-column> -->
		</jy-table>
	</div>
</template>
<script>
import dayjs from "dayjs";

export default {
	name: "CarList",
	data() {
		return {
			formData: {
				customNo: "",
				vehicleNo: "",
				routeId: ""
			},
			dataList: [],
			lineList: [],
			statusMap: {
				0: "未发车",
				1: "已发车",
				2: "取消派发"
			}
		};
	},
	watch: {
		vehicleId: {
			handler() {
				console.log("-----z7klan");
				this.reset();
			},
			immediate: true
		}
	},
	created() {
		this.getLineInfo();
		this.init();
	},
	methods: {
		init() {
			const url = "/waybillPlan/queryPage";
			let params = {
				...this.formData,
				oDate: dayjs().format("YYYY-MM-DD")
			};
			this.$http.post(url, params).then(res => {
				this.dataList = res?.detail || [];
			});
		},
		getLineInfo() {
			const url = `/crrc-palm-bus/gis/line/lineInfo`;
			const params = {};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.lineList = res || [];
			});
		},
		reset() {
			this.formData = {
				customNo: "",
				vehicleNo: "",
				routeId: ""
			};
			this.init();
		}
	}
};
</script>
<style lang="scss" scoped>
.car-list {
	::v-deep {
		.el-input {
			width: 146px;
			color: #ffffff;
		}
		.el-form-item__label {
			color: #ffffff;
		}
	}
}
</style>
