<template>
	<div class="tabs home-page-new-container">
		<div class="tabs-top">
			<span :class="isCurrentDay ? 'active' : ''" @click="changeHandle(true)">当天计划</span>
			<span :class="isCurrentDay ? '' : 'active'" @click="changeHandle(false)">调度任务</span>
		</div>
		<div class="tabs-content">
			<jy-table max-height="595" :data="dataList">
				<jy-table-column prop="planTime" label="计划发车" min-width="120"></jy-table-column>
				<jy-table-column prop="actualPlanTime" label="实际发车" min-width="120"></jy-table-column>
				<jy-table-column prop="planReachTime" label="计划到站" min-width="120">
					<!-- <template v-slot="{ row }">
						{{ row.reachTime | filterTime }}
					</template> -->
				</jy-table-column>
				<jy-table-column prop="actualReachTime" label="实际回场" min-width="120"> </jy-table-column>
			</jy-table>
		</div>
	</div>
</template>
<script>
import dayjs from "dayjs";

export default {
	name: "taskInfo",
	data() {
		return {
			dataList: [],
			isCurrentDay: true
		};
	},
	props: {
		vehicleId: {
			type: String,
			default: ""
		}
	},
	filters: {
		filterTime(val) {
			if (val) {
				return dayjs().add(val, "s").format("HH: mm");
			}
			return "-";
		}
	},
	watch: {
		vehicleId: {
			handler(val) {
				this.vehicleId = val;
				val && this.init();
			},
			immediate: true
		}
	},
	methods: {
		init() {
			const url = this.isCurrentDay
				? "/crrc-palm-bus/realtimeOperation/getVehicleTodayPlan"
				: "/crrc-palm-bus/realtimeOperation/getVehicleActualWaybill";
			const params = {
				vehicleId: this.vehicleId
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.dataList = res || [];
			});
		},
		changeHandle(flag) {
			this.isCurrentDay = flag;
			this.init();
		}
	}
};
</script>
<style lang="scss" scoped>
.tabs {
	padding: 15px;
	&-top {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		padding-bottom: 13px;
		span {
			display: inline-block;
			cursor: pointer;
			padding: 0 12px;
			&.active {
				border-bottom: 3px solid #2a73ff;
				padding-bottom: 13px;
				margin-bottom: -13px;
			}
		}
	}
	&-content {
		padding: 13px 0;
		width: 580px;
	}
}
.sign {
	background: rgba(255, 255, 255, 0.05);
	padding: 20px;
	.top {
		display: flex;
		padding: 9px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		margin-bottom: 12px;
		& > div {
			flex: 1;
		}
	}
	li {
		display: flex;
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
		& > div {
			flex: 1;
		}
	}
}
</style>
