<template>
	<div class="schdule-plan-table">
		<div class="se-tab">
			<div class="tab-item" :class="isPlan ? 'active' : ''" @click="changeHandle(true)">计划排班</div>
			<div class="tab-item" :class="isPlan ? '' : 'active'" @click="changeHandle(false)">实际执行</div>
		</div>
		<div class="table-content">
			<PlanTable
				:isPlan="isPlan"
				:tableList="upDataList"
				@reload="init"
				@show-car="onShowCar"
				@show-driver="onShowDriver"
			></PlanTable>
			<PlanTable
				:isPlan="isPlan"
				:tableList="downDataList"
				@reload="init"
				@show-car="onShowCar"
				@show-driver="onShowDriver"
			></PlanTable>
		</div>
	</div>
</template>

<script>
import PlanTable from "./planTable.vue";
import dayjs from "dayjs";

export default {
	components: {
		PlanTable
	},
	data() {
		return {
			isPlan: true,
			dataList: [],
			upDataList: [],
			downDataList: []
		};
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		lineInfo(obj) {
			obj.routeId && this.init();
		}
	},
	methods: {
		init() {
			const url = this.isPlan ? "/waybillPlan/queryPage" : "/waybillActual/queryPage";
			const params = this.isPlan
				? {
						routeId: this.lineInfo.routeId,
						oDate: dayjs().format("YYYY-MM-DD")
					}
				: {
						routeIds: this.lineInfo.routeId,
						oDate: dayjs().format("YYYY-MM-DD"),
						pageIndex: 1,
						pageSize: 9999
					};
			this.$http.post(url, params).then(res => {
				this.dataList = this.isPlan ? res?.detail || [] : res?.detail?.list || [];
				this.dataList = this.dataList.map(item => {
					this.$set(item, "disabled", false);
					return item;
				});
				this.upDataList = this.dataList
					.filter(item => item.schemeT == 1)
					.sort((a, b) => {
						return a.eState - b.eState;
					});
				this.downDataList = this.dataList
					.filter(item => item.schemeT == 2)
					.sort((a, b) => {
						return a.eState - b.eState;
					});
			});
		},
		changeHandle(flag) {
			this.isPlan = flag;
			this.upDataList = [];
			this.downDataList = [];
			this.init();
		},
		onShowCar(obj) {
			this.$emit("showCarInfo", obj);
		},
		onShowDriver(obj) {
			this.$emit("showDriverInfo", obj);
		}
	}
};
</script>

<style lang="scss">
.schdule-plan-table {
	padding: 14px 10px;
	.se-tab {
		padding-left: 17px;
		border-bottom: solid 1px rgba(255, 255, 255, 0.2);
		display: flex;
		.tab-item {
			padding: 8px 20px;
			border: 1px solid #2a73ff;
			border-radius: 4px 4px 0 0;
			border-bottom: none;
			margin-right: 12px;
			font-size: 14px;
			color: #ffffff;
			line-height: 20px;
			cursor: pointer;
			&.active {
				background: #2a73ff;
			}
		}
	}
	.table-content {
		padding: 2px 17px;
		display: flex;
		justify-content: space-between;
		.ope-btn {
			padding: 0;
		}
		.cur-po {
			cursor: pointer;
		}
		.tip-icon {
			display: inline-block;
			width: 14px;
			height: 16px;
			background: url("~@/assets/homeNew/index/tip_icon.png") no-repeat;
			background-size: 100% 100%;
			cursor: pointer;
			&.not-allow {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
		.plan-table-content {
			width: calc((100% - 20px) / 2);
		}
		.e-state {
			display: flex;
			align-items: center;
			justify-content: center;
			.icon {
				width: 12px;
				height: 12px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				margin-right: 2px;
			}
			.text {
				font-size: 14px;
				font-weight: 600;
			}
			&.e-state-0-0 {
				.icon {
					background-image: url("~@/assets/homeNew/index/wating_go_icon.png");
				}
				.text {
					color: #ff926b;
				}
			}
			&.e-state-0-1 {
				.icon {
					background-image: url("~@/assets/homeNew/index/has_go_icon.png");
				}
				.text {
					color: #50abf5;
				}
			}
			&.e-state-0-2 {
				.icon {
					background-image: url("~@/assets/homeNew/index/cancel_go_icon.png");
				}
				.text {
					color: #999999;
				}
			}
			&.e-state-1-1 {
				.icon {
					background-image: url("~@/assets/homeNew/index/has_go_icon.png");
				}
				.text {
					color: #50abf5;
				}
			}
			&.e-state-1-2 {
				.icon {
					background-image: url("~@/assets/homeNew/index/finish_go_icon.png");
				}
				.text {
					color: #5ac67f;
				}
			}
			&.e-state-1-3 {
				.icon {
					background-image: url("~@/assets/homeNew/index/outtime_go_icon.png");
				}
				.text {
					color: #fa6400;
				}
			}
			&.e-state-1-4 {
				.icon {
					background-image: url("~@/assets/homeNew/index/error_go_icon.png");
				}
				.text {
					color: #e02020;
				}
			}
		}
	}
}
</style>
