<template>
	<div class="station-container">
		<div class="flex-1">
			<ul class="info-list">
				<li>站点名称：{{ siteInfo.siteName || "--" }}</li>
				<li>
					<div>当前上车人数：{{ siteInfo.inCount }}</div>
					<div>当前下车人数：{{ siteInfo.outCount }}</div>
				</li>
			</ul>
			<div class="charts-container" ref="chartRef"></div>
		</div>
		<div class="button-list">
			<el-button type="primary" @click="openMap">电子地图</el-button>
		</div>
		<el-dialog :modal="false" title="电子地图" :visible.sync="showEleMap" width="800">
			<div class="content-wrap">
				<ele-map :coordinate="{ lng: siteInfo.longitude, lat: siteInfo.latitude }" :route-id="routeId" :showDialog="showEleMap" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
import * as echarts from "echarts";
import EleMap from "../../components/eleMap/index.vue";
export default {
	name: "stationInfo",
	props: {
		siteId: { type: String, required: true, default: "" },
		schemeT:{ type: String, required: true, default: "" },
		dialogVisible: {
			type: Boolean,
			default: false
		},
		routeId: {
			type: String,
			default: ""
		}
	},
	components: { EleMap },
	/* mounted() {
		this.initCharts();
	}, */
	data() {
		return { siteInfo: {}, showEleMap: false };
	},
	watch: {
		dialogVisible: {
			handler(val) {
				if (val) {
					this.$nextTick(() => {
						this.initCharts();
					});
				}
			},
			immediate: true
		}
	},
	methods: {
		initCharts(siteId) {
			const charts = echarts.init(this.$refs.chartRef);
			const url = "/crrc-palm-bus/realtimeOperation/getSiteDetails";
			const id = siteId || this.siteId;
			const t = this.schemeT;
			if (id) {
				this.$http.post(url, { siteId: id, schemeT:t}, { baseType: "base" }).then(res => {
					const { sitePassengers } = res;
					let realData = {};
					let passData = {};
					sitePassengers.forEach(element => {
						if (element) {
							const keys = Object.keys(element);
							const values = Object.values(element);
							const y = values.slice(5, values.length - 1);
							const x = keys.slice(5, keys.length - 1).map((item, index) => {
								return index + 7 < 10 ? `0${index + 7}:00` : `${index + 7}:00`;
							});
							if (new Date().getDate() === new Date(element.dataDate).getDate()) {
								console.log(element.dataDate);
								realData = { x, y };
							} else {
								passData = { x, y };
							}
						}
					});
					this.siteInfo = { ...res };
					charts.setOption({
						color: ["#1890FF", "#ABBFE5"],
						title: { text: "实时客流", textStyle: { color: "#ffffff" } },
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985"
								}
							}
						},
						legend: {
							data: ["实时", "昨天"],
							textStyle: { color: "#ffffff" }
						},
						xAxis: [
							{
								type: "category",
								boundaryGap: false,
								axisLabel: { color: "#ffffff" },
								data: realData.x
							}
						],
						yAxis: [
							{
								type: "value",
								axisLabel: { color: "#ffffff" }
							}
						],
						series: [
							{
								name: "昨天",
								type: "line",
								stack: "Total",
								smooth: true,
								lineStyle: {
									width: 0
								},
								showSymbol: false,
								areaStyle: {
									opacity: 1,
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{
											offset: 0,
											color: "rgba(171, 191, 229, 1)"
										},
										{
											offset: 1,
											color: "rgba(255, 255, 255, 0.1)"
										}
									])
								},
								emphasis: {
									focus: "series"
								},
								data: passData.y
							},
							{
								name: "实时",
								type: "line",
								smooth: true,
								lineStyle: {
									width: 0
								},
								showSymbol: false,
								areaStyle: {
									opacity: 0.8,
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{
											offset: 0,
											color: "rgba(24, 144, 255, 1)"
										},
										{
											offset: 1,
											color: "rgba(255, 255, 255, 0.1)"
										}
									])
								},
								emphasis: {
									focus: "series"
								},
								data: realData.y
							}
						]
					});
				});
			}
		},
		openMap() {
			console.log(this.siteInfo, "---SiteInfo");
			this.showEleMap = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.station-container {
	padding: 35px;
	display: flex;
	.flex-1 {
		flex: 1;
	}
	.button-list {
		width: 88px;
	}
	.info-list {
		li {
			display: flex;
			margin-top: 12px;
			&:first-child {
				margin: 0;
			}
			& > div {
				flex: 1;
			}
		}
	}
	.charts-container {
		height: 253px;
		width: 642px;
		margin-top: 62px;
	}
	.content-wrap {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
</style>
