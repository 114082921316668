<template>
	<div clas="content">
		<div class="buttons">
			<el-date-picker
				v-model="taskTime"
				type="datetimerange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				value-format="yyyy-MM-dd HH:mm:ss"
				format="yyyy-MM-dd HH:mm:ss"
				:picker-options="pickerOptions"
				unlink-panels
				@change="changTime"
				@focus="focusTime"
			/>
			<!-- </el-date-picker>
			<jy-query ref="checkForm" :model="diaform">
				<jy-query-item label="开始时间" prop="startTime" :span="10">
					<el-date-picker
						v-model="diaform.startTime"
						value-format="yyyy-MM-dd HH:mm:ss"
						:picker-options="startTime"
						type="datetime"
						placeholder="选择日期"
						popper-class="no-atTheMoment"
						label-width="250px"
					></el-date-picker>
				</jy-query-item>
				<jy-query-item label="结束时间" prop="endTime" :span="10">
					<el-date-picker
						v-model="diaform.endTime"
						value-format="yyyy-MM-dd HH:mm:ss"
						:picker-options="endTime"
						type="datetime"
						placeholder="选择日期"
						popper-class="no-atTheMoment"
					></el-date-picker>
				</jy-query-item>
			</jy-query> -->
			<el-button class="button" type="primary" @click="oncheck">查询</el-button>
		</div>

		<el-divider></el-divider>
		<div class="top-content">
			<div class="left">
				<div class="spd">
					<p class="value">{{ carData.ovInfo?.cspd || "--" }}</p>
					<p class="unit">km/h</p>
					<p class="text">车速</p>
				</div>
				<div class="stop">
					<div class="left">
						<p class="oli">油门</p>
						<p class="stop-text">刹车</p>
					</div>
					<div class="middle"></div>
					<div class="right">
						<div class="top">
							<div class="top-area" :style="{ width: `${carData.ovInfo?.accelv || 0}%` }"></div>
						</div>
						<div class="bottom">
							<div class="bottom-area" :style="{ width: `${carData.ovInfo?.breaks || 0}%` }"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="elect">
				<div class="elect-item">
					<div class="top">
						<span class="icon"></span>
						<span class="text">电机电压</span>
					</div>
					<div class="bottom">
						<span class="value">{{ carData?.emotor ? carData?.emotor[0]?.civol || "--" : "--" }}</span>
						<span class="unit">V</span>
					</div>
				</div>
				<div class="elect-item">
					<div class="top">
						<span class="icon icon2"></span>
						<span class="text">电机电流</span>
					</div>
					<div class="bottom">
						<span class="value">{{ carData?.emotor ? carData?.emotor[0]?.ccur || "--" : "--" }}</span>
						<span class="unit">A</span>
					</div>
				</div>
				<div class="elect-item">
					<div class="top">
						<span class="icon"></span>
						<span class="text">电池电压</span>
					</div>
					<div class="bottom">
						<span class="value">{{ carData.hfuel?.vol || "--" }}</span>
						<span class="unit">V</span>
					</div>
				</div>
				<div class="elect-item">
					<div class="top">
						<span class="icon icon2"></span>
						<span class="text">电池电流</span>
					</div>
					<div class="bottom">
						<span class="value">{{ carData.hfuel?.cur || "--" }}</span>
						<span class="unit">A</span>
					</div>
				</div>
			</div>
			<div class="wendu">
				<div class="wendu-item">
					<p class="text">电机温度</p>
					<p class="value">{{ carData?.emotor ? carData?.emotor[0]?.temp || "--" : "--" }}°C</p>
				</div>
				<div class="wendu-item">
					<p class="text">控制器温度</p>
					<p class="value">{{ carData?.emotor ? carData?.emotor[0]?.ctemp || "--" : "--" }}°C</p>
				</div>
				<div class="wendu-item">
					<p class="text">单体最低温度</p>
					<p class="value green">{{ carData.limit?.mintempv || "--" }}°C</p>
				</div>
				<div class="wendu-item">
					<p class="text">单体最高温度</p>
					<p class="value green">{{ carData.limit?.mtempv || "--" }}°C</p>
				</div>
			</div>
		</div>
		<div class="track-map" ref="trackContainer"></div>

		<div class="track-control">
			<el-button :disabled="isRunning" type="primary" size="small" @click="start">开始</el-button>

			<el-button :disabled="!isRunning" type="primary" size="small" @click="pause">暂停</el-button>

			<el-button :disabled="!isRunning" type="primary" size="small" @click="stop">结束</el-button>
		</div>
	</div>
</template>

<script>
import gcoord from "gcoord";
import LuShu from "@/common/js/BMapLib/LuShu.js";
import startImage from "@/assets/startIcon.png";
import endImage from "@/assets/endIcon.png";
// import dayjs from "dayjs";

let trackMap = null,
	lushu = null;

export default {
	props: {
		vehicleId: {
			type: String,
			default: ""
		},
		showDialog: {
			type: Boolean,
			default: false
		}
	},
	/* mounted() {
		this.init();
		// const now = new Date();
		// this.diaform.startTime = new Date(now.getTime() - 60 * 60 * 1000).Format("yyyy-MM-dd hh:mm:ss");
		// this.diaform.endTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
		this.$nextTick(() => {
			this.initMap();
		});
	},
	destroyed() {
		if (this.isRunning) {
			this.stop();
		}
		clearInterval(this.timer);
		trackMap.clearOverlays();
		trackMap = null;
		lushu = null;
		// gpsData = [];
	}, */
	methods: {
		myDestroyed() {
			if (this.isRunning) {
				this.stop();
			}
			clearInterval(this.timer);
			trackMap.clearOverlays();
			trackMap = null;
			lushu = null;
		},
		oncheck() {
			if (this.isRunning) {
				this.stop();
			}
			clearInterval(this.timer);
			trackMap.clearOverlays();
			this.runData = [];
			this.carData = {};
			if (!this.diaform.startTime || !this.diaform.endTime) {
				this.$message({
					message: "数据量太大，时间间隔请不要选择超过3小时",
					type: "error"
				});
			} else {
				// const options = {
				// 	orgId: this.routeId,
				// 	clientId: this.clientId,
				// 	vehicleId: this.vehicleId
				// };
				this.init();
			}
		},
		init() {
			console.log("进入");
			// this.routeId = options.orgId;
			// this.clientId = options.clientId;
			// this.vehicleId = options.vehicleId;
			this.getTrack();
			const url = "/crrc-palm-bus/vehicleMonitor/queryGb32960HistoryList";
			this.$http
				.post(
					url,
					{ vehicleId: this.vehicleId, startTime: this.diaform.startTime, endTime: this.diaform.endTime },
					{ baseType: "base" }
				)
				.then(res => {
					this.carData = res || {};
					console.log("queryGb32960HistoryList", this.carData);
					if (res.length > 0) {
						this.hasResData = true;
						this.runData = res;
					}
				});
		},
		initMap() {
			const dom = this.$refs.trackContainer;
			trackMap = new BMap.Map(dom);
			trackMap.enableScrollWheelZoom();
			trackMap.centerAndZoom(new BMap.Point(89.187441, 42.957145), 13);
		},
		getProgressList() {
			const value = this.carData.ovInfo?.soc || 0;
			const unit = 100 / 20;
			const num = Math.round(value / unit);
			this.progressNum = num;
		},
		start() {
			clearInterval(this.timer);
			lushu.start();
			this.isRunning = true;
			this.handPathPlay();
		},
		pause() {
			lushu.pause();
			this.isRunning = false;
			this.handPathPlay();
		},
		stop() {
			lushu.stop();
			this.isRunning = false;
			this.handPathPlay();
		},
		handPathPlay() {
			this.$nextTick(() => {
				// this.isRunning = !this.isRunning;
				if (this.isRunning == true) {
					this.timer = setInterval(() => {
						if (this.isRunning == true) {
							const index = lushu?._overlay?.lushuMain?.i;
							console.log("index", index);
							this.handleData(index);
						}
						if (this.isRunning == false) {
							clearInterval(this.timer);
						}
					}, 300);
				} else {
					clearInterval(this.timer);
				}
			});
		},
		handleData(index) {
			if (this.runData.length > index) {
				this.carData = this.runData[index];
			}
		},
		getTrack() {
			let url = "/crrc-palm-bus/vehicleMonitor/queryGb32960HistoryTrack";
			this.$http
				.post(
					url,
					{ vehicleId: this.vehicleId, startTime: this.diaform.startTime, endTime: this.diaform.endTime },
					{ baseType: "base" }
				)
				.then(res => {
					// 过滤掉经纬度为0的脏数据
					let poiTrack = res.filter(item => {
						return item.lng != "000.000000" && item.lat != "00.000000";
					});
					const arrPois = poiTrack.map(v => {
						const [lng, lat] = gcoord.transform([v.lng, v.lat], gcoord.WGS84, gcoord.BD09);
						return new BMap.Point(lng, lat);
					});
					console.log("arrPois", arrPois);
					this.getPolyline(arrPois);
					trackMap.setViewport(arrPois);

					//   添加标注
					if (arrPois.length) {
						let startPoint = new window.BMap.Point(arrPois[0].lng, arrPois[0].lat);
						let endPoint = new window.BMap.Point(arrPois[arrPois.length - 1].lng, arrPois[arrPois.length - 1].lat);
						var startIcon = new BMap.Icon(startImage, new BMap.Size(50, 50));
						var endIcon = new BMap.Icon(endImage, new BMap.Size(50, 50));
						let marker = new BMap.Marker(startPoint, {
							icon: startIcon
						});
						let endmarker = new BMap.Marker(endPoint, {
							icon: endIcon
						});
						trackMap.addOverlay(marker);
						trackMap.addOverlay(endmarker);

						trackMap.setCenter(startPoint);
						trackMap.setZoom(14);
					}

					this.getLushu(arrPois);
				});
		},
		// 创建轨迹
		getPolyline(trackList) {
			let tempList = [trackList[0]];
			for (let index = 1; index < trackList.length; index++) {
				const f = trackList[index - 1];
				const l = trackList[index];
				if (BMapLib.GeoUtils.getDistance(f, l) <= 300) {
					tempList.push(l);
				} else {
					// 绘制
					const polyline = new BMap.Polyline(tempList, {
						strokeColor: "blue", //设置颜色
						strokeWeight: 5, //宽度
						strokeOpacity: 0.5 //透明度
					});
					trackMap.addOverlay(polyline);
					tempList = [l];
				}
			}
			if (tempList.length > 1) {
				const polyline = new BMap.Polyline(tempList, {
					strokeColor: "blue", //设置颜色
					strokeWeight: 5, //宽度
					strokeOpacity: 0.5 //透明度
				});
				trackMap.addOverlay(polyline);
			}
		},
		// 创建路书
		getLushu(arrPois) {
			console.log("创建路书", arrPois);
			lushu = new LuShu(trackMap, arrPois, {
				defaultContent: "", //"从天安门到百度大厦"
				autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
				icon: new BMap.Icon("https://developer.baidu.com/map/jsdemo/img/car.png", new BMap.Size(52, 26), {
					anchor: new BMap.Size(27, 13)
				}),
				speed: 200, //运行速度
				enableRotation: true, //是否设置marker随着道路的走向进行旋转
				landmarkPois: [
					{
						lng: 116.314782,
						lat: 39.913508,
						html: "加油站",
						pauseTime: 2
					}
				]
			});
		},
		pick(time) {
			console.log("---------time", time);
			if (time) {
				const start = time[0];
				const end = time[1];
				const diff = Math.abs(new Date(end) - new Date(start));
				const hours = diff / 1000 / 60 / 60;
				if (hours > 3) {
					this.$message({
						message: "数据量太大，时间间隔请不要选择超过3小时",
						type: "error"
					});
				} else {
					this.taskTime = time;
				}
			}
		},
		focusTime(time) {
			console.log(time, "---foucs");
			this.focusTaskTime = this.taskTime;
		},
		changTime(time) {
			const current = this.taskTime;
			if (time) {
				const start = time[0];
				const end = time[1];
				const diff = Math.abs(new Date(end) - new Date(start));
				const hours = diff / 1000 / 60 / 60;
				if (hours > 3) {
					this.$message({
						message: "数据量太大，时间间隔请不要选择超过3小时",
						type: "error"
					});
					console.log("------current", current);
					this.taskTime = this.focusTaskTime;
					return;
				} else {
					this.taskTime = time;
				}
			}
		}
	},
	data() {
		const now = new Date();
		return {
			routeId: "",
			clientId: "",
			isRunning: false,
			startTime: {
				disabledDate: time => {
					if (this.diaform.endTime) {
						return time.getTime() >= new Date(this.diaform.endTime).getTime() - 8.64e7;
					} else {
						return time.getTime() > Date.now();
					}
				}
			},
			carData: {
				emotor: []
			},
			runData: [],
			hasResData: true,
			progressNum: 0,
			diaform: {
				startTime: new Date(now.getTime() - 60 * 60 * 1000).Format("yyyy-MM-dd hh:mm:ss"),
				endTime: new Date().Format("yyyy-MM-dd hh:mm:ss")
			},
			taskTime: [
				new Date(now.getTime() - 60 * 60 * 1000).Format("yyyy-MM-dd hh:mm:ss"),
				new Date().Format("yyyy-MM-dd hh:mm:ss")
			],
			focusTaskTime: null,
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一个小时",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 60 * 60 * 1000);
							picker.$emit("pick", [start, end]);
						}
					},
					{
						text: "最近两个小时",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 2);
							picker.$emit("pick", [start, end]);
						}
					},
					{
						text: "最近三个小时",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 3);
							picker.$emit("pick", [start, end]);
						}
					}
				]
			}
		};
	}, //new Date(-3600 * 1000 * 1).Format("yyyy-MM-dd hh:mm:ss")
	watch: {
		taskTime: function (val) {
			if (val) {
				this.diaform.startTime = val[0];
				this.diaform.endTime = val[1];
			}
		},
		showDialog: {
			async handler(val) {
				await this.$nextTick();
				if (val) {
					this.init();
					this.initMap();
				} else {
					this.myDestroyed();
				}
			},
			immediate: true
		}
	},
	computed: {
		endTime() {
			let hour = "";
			let minu = "";
			let second = "";
			if (this.diaform.startTime != "" && this.diaform.startTime != null) {
				hour = new Date(this.diaform.startTime).getHours();
				minu = new Date(this.diaform.startTime).getMinutes();
				second = new Date(this.diaform.startTime).getSeconds();
			}
			return {
				disabledDate: time => {
					if (this.diaform.startTime != "" && this.diaform.startTime != null) {
						if (hour == 0) {
							return (
								time.getTime() > new Date(this.diaform.startTime).getTime() ||
								time.getTime() < new Date(this.diaform.startTime).getTime() - 8.64e7 + 3600000
							);
						} else {
							return (
								(time.getTime() > new Date(this.diaform.startTime).getTime()) |
								(time.getTime() < new Date(this.diaform.startTime).getTime() - 8.64e7)
							);
						}
					} else {
						return time.getTime() > Date.now();
					}
				},
				selectableRange: hour + ":" + minu + ":" + second + "-" + (hour + 3) + ":" + minu + ":" + second
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.buttons {
	width: 100%;
	height: auto;
	display: flex;
	// flex: 1;
	// display: flex;
	flex-direction: row;
	.button {
		margin-left: 20px;
		margin-right: 20px;
		width: 100px;
	}
}
.top-content {
	height: 80px;
	margin-top: -10px;
	// background-color: #00fff1;
	// margin-left: 15px;
	// display: flex;
	flex-direction: column;
	.left {
		width: calc(100% / 3);
		float: left;
		.spd {
			height: 123px;
			width: 108px;
			background: url("./images/spd_bg.png") no-repeat;
			background-size: 100% 100%;
			padding-top: 40px;
			margin-left: 20px;
			.value {
				font-size: 16px;
				font-weight: 600;
				color: white;
				line-height: 14px;
				margin-top: 8px;
				margin-left: 40px;
			}
			.unit {
				font-size: 12px;
				color: white;
				line-height: 12px;
				margin-left: 41px;
			}
			.text {
				font-size: 10px;
				font-weight: 600;
				color: white;
				line-height: 8px;
				margin-top: 6px;
				margin-left: 41px;
			}
		}
		.stop {
			display: flex;
			align-items: center;
			// margin-top: -68px;
			.left {
				margin-left: 3px;
				width: 20px;
				.oli,
				.stop-text {
					font-size: 10px;
					color: white;
					line-height: 8px;
				}
				.oli {
					margin-top: -25px;
					margin-bottom: 8px;
				}
			}
			// .middle {
			// 	width: 1px;
			// 	height: 30px;
			// 	background: #6c8097;
			// 	margin-left: 7px;
			// 	margin-top: -18px;
			// }
			.right {
				margin-top: -20px;
				margin-left: 6px;
				.top,
				.bottom {
					width: 90px;
					height: 10px;
					background: rgba(108, 128, 151, 0.1);
					.top-area {
						height: 5px;
						background: linear-gradient(90deg, rgba(156, 255, 8, 0) 0%, #00fff1 100%);
					}
					.bottom-area {
						height: 5px;
						background: linear-gradient(90deg, rgba(255, 87, 56, 0) 0%, #ff5738 100%);
					}
				}
				.top {
					margin-top: 0px;
					margin-bottom: 6px;
				}
			}
		}
	}
	.elect {
		width: calc(100% / 3);
		margin-left: -30px;
		margin-top: 10px;
		float: left;
		border: 1px solid;
		border-image: linear-gradient(180deg, rgba(200, 200, 200, 0.1), rgba(21, 91, 114, 1), rgba(151, 151, 151, 0.1)) 1 1;
		border: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 7px 0;
		.elect-item {
			width: 50%;
			padding: 7px 0 7px 6px;
			.top {
				display: flex;
				align-items: center;
				.icon {
					width: 14px;
					height: 14px;
					background: url("~@/assets/bigScreen/dashboard-car/elect_1.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 4px;
					&.icon2 {
						background: url("~@/assets/bigScreen/dashboard-car/elect_2.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.text {
					font-size: 14px;
					color: white;
					line-height: 14px;
				}
			}
			.bottom {
				padding-left: 17px;
				margin-top: 6px;
				.value {
					font-size: 16px;
					font-weight: 600;
					color: #00caff;
					line-height: 17px;
				}
				.unit {
					font-size: 12px;
					color: #d3d3d3;
					margin-left: 4px;
				}
			}
		}
	}
	.wendu {
		display: flex;
		width: calc(100% / 3);
		// background-color: red;
		float: left;
		padding-top: 20px;
		padding-left: 4px;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		.wendu-item {
			margin-bottom: 12px;
			width: 99px;
			height: 42px;
			text-align: center;
			padding-top: 5px;
			// background: url("~@/assets/bigScreen/dashboard-car/t_bg.png") no-repeat;

			background-size: 100% 100%;
			.value {
				font-size: 16px;
				color: #00caff;
				line-height: 17px;

				&.green {
					color: green;
				}
				&.orange {
					color: #fd5738;
				}
			}
			.text {
				font-size: 14px;
				padding-bottom: 8px;
				color: white;
				line-height: 14px;
			}
		}
	}
}
.track-map {
	margin-top: 60px;
	height: 330px;
}
.track-control {
	margin-top: 10px;
	text-align: center;
}
</style>
