import pre_send from "@/assets/homeNew/audio/pre_send.mp3";
import place_send from "@/assets/homeNew/audio/place_send.mp3";
import 临233363 from "@/assets/homeNew/audio/临233363.mp3";
import 临233364 from "@/assets/homeNew/audio/临233364.mp3";
import 临233365 from "@/assets/homeNew/audio/临233365.mp3";
import 临233366 from "@/assets/homeNew/audio/临233366.mp3";
import 临233367 from "@/assets/homeNew/audio/临233367.mp3";
import 临233368 from "@/assets/homeNew/audio/临233368.mp3";
import 临233369 from "@/assets/homeNew/audio/临233369.mp3";
import 临233370 from "@/assets/homeNew/audio/临233370.mp3";
import 临233371 from "@/assets/homeNew/audio/临233371.mp3";
import 临233372 from "@/assets/homeNew/audio/临233372.mp3";
import 临233373 from "@/assets/homeNew/audio/临233373.mp3";
import 临233374 from "@/assets/homeNew/audio/临233374.mp3";
import 临233375 from "@/assets/homeNew/audio/临233375.mp3";
import 临233376 from "@/assets/homeNew/audio/临233376.mp3";
import 临233377 from "@/assets/homeNew/audio/临233377.mp3";
import 临233378 from "@/assets/homeNew/audio/临233378.mp3";
import 临233379 from "@/assets/homeNew/audio/临233379.mp3";
import 临233380 from "@/assets/homeNew/audio/临233380.mp3";
import 临233381 from "@/assets/homeNew/audio/临233381.mp3";
import 临233382 from "@/assets/homeNew/audio/临233382.mp3";
import 临233383 from "@/assets/homeNew/audio/临233383.mp3";
import 临233384 from "@/assets/homeNew/audio/临233384.mp3";
import 临233385 from "@/assets/homeNew/audio/临233385.mp3";
import 临233386 from "@/assets/homeNew/audio/临233386.mp3";
import 临240280 from "@/assets/homeNew/audio/临240280.mp3";
import 临240281 from "@/assets/homeNew/audio/临240281.mp3";
import 临240282 from "@/assets/homeNew/audio/临240282.mp3";
import 临240283 from "@/assets/homeNew/audio/临240283.mp3";
import 临240284 from "@/assets/homeNew/audio/临240284.mp3";
import 临240285 from "@/assets/homeNew/audio/临240285.mp3";
import 临240286 from "@/assets/homeNew/audio/临240286.mp3";
import 临240287 from "@/assets/homeNew/audio/临240287.mp3";
import 临240288 from "@/assets/homeNew/audio/临240288.mp3";
import 临240289 from "@/assets/homeNew/audio/临240289.mp3";
import 临240290 from "@/assets/homeNew/audio/临240290.mp3";
import 临240291 from "@/assets/homeNew/audio/临240291.mp3";
import 临240292 from "@/assets/homeNew/audio/临240292.mp3";
import 临240293 from "@/assets/homeNew/audio/临240293.mp3";
import 临240294 from "@/assets/homeNew/audio/临240294.mp3";
import 临240295 from "@/assets/homeNew/audio/临240295.mp3";
import 临240296 from "@/assets/homeNew/audio/临240296.mp3";
import 临240297 from "@/assets/homeNew/audio/临240297.mp3";
import 临240298 from "@/assets/homeNew/audio/临240298.mp3";
import 临240299 from "@/assets/homeNew/audio/临240299.mp3";
import 新K22666D from "@/assets/homeNew/audio/新K22666D.mp3";
import 新K23988D from "@/assets/homeNew/audio/新K23988D.mp3";
import 新K25656D from "@/assets/homeNew/audio/新K25656D.mp3";
import 新K28188D from "@/assets/homeNew/audio/新K28188D.mp3";
import 新k29898D from "@/assets/homeNew/audio/新k29898D.mp3";
const audioObj = {
	pre_send,
	place_send,
	临233363,
	临233364,
	临233365,
	临233366,
	临233367,
	临233368,
	临233369,
	临233370,
	临233371,
	临233372,
	临233373,
	临233374,
	临233375,
	临233376,
	临233377,
	临233378,
	临233379,
	临233380,
	临233381,
	临233382,
	临233383,
	临233384,
	临233385,
	临233386,
	临240280,
	临240281,
	临240282,
	临240283,
	临240284,
	临240285,
	临240286,
	临240287,
	临240288,
	临240289,
	临240290,
	临240291,
	临240292,
	临240293,
	临240294,
	临240295,
	临240296,
	临240297,
	临240298,
	临240299,
	新K22666D,
	新K23988D,
	新K25656D,
	新K28188D,
	新k29898D
};

export const playAudio = audioKeyArr => {
	return new Promise((resolve, reject) => {
		if (audioKeyArr.length === 0) {
			resolve();
		} else {
			const audio = new Audio();
			audio.preload = true;
			audio.controls = true;
			audio.src = audioObj[audioKeyArr.shift()];
			const promise = audio.play();
			promise.catch(err => {
				reject();
				console.log("播放失败----", err);
			});
			audio.onended = () => {
				if (audioKeyArr.length === 0) {
					resolve();
				} else {
					audio.src = audioObj[audioKeyArr.shift()];
					const promise = audio.play();
					promise.catch(err => {
						reject();
						console.log("播放失败--onended", err);
					});
				}
			};
		}
	});
};
