<template>
	<div class="car-data">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane :label="carDes.vehicleNo" name="cartInfo">
				<div class="car-data-current flex">
					<div class="car-info flex-1">
						<div class="flex">
							<ul class="flex-1">
								<li><img src="./images/car.png" style="width: 200px; height: 120px; margin-bottom: 15px" alt="" /></li>
								<li class="flex">
									<div class="label">GPS信号：</div>
									<div class="flex-1">
										<span class="blue-text">{{ carDes.yztGb32960HistoryVo?.ovInfo?.vs == 0 ? "离线" : "在线" }}</span>
									</div>
								</li>
								<li class="flex">
									<div class="label">当前车速：</div>
									{{ carDes?.yztGb32960HistoryVo?.ovInfo?.cspd || "-" }}km/h
								</li>
								<li class="flex">
									<div class="label">限速：</div>
									<div class="flex-1">-</div>
								</li>
								<li class="flex">
									<div class="label">车辆状态：</div>
									<div class="flex-1">{{ carDes.isInOut == 0 ? "停车场" : "在途" }}</div>
								</li>
								<li class="flex">
									<div class="label">当前里程：</div>
									<div class="flex-1">{{ carDes?.yztGb32960HistoryVo?.ovInfo?.mil || "-" }}km</div>
								</li>
							</ul>
							<ul class="flex-1">
								<li class="flex">
									<div class="label">车牌号：</div>
									<div class="flex-1">{{ carDes.vehicleNo || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">编号：</div>
									<div class="flex-1">{{ carDes.customNo || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">计划司机：</div>
									<div class="flex-1">{{ carDes.planDriver || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">签班司机：</div>
									<div class="flex-1">{{ carDes.signDriver || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">乘务：</div>
									<div class="flex-1">-</div>
								</li>
							</ul>
							<ul class="flex-1">
								<li class="flex">
									<div class="label">进出场：</div>
									<div class="flex-1">{{ carDes.isInOut == 0 ? "车场" : "在途" }}</div>
								</li>
								<li class="flex">
									<div class="label">离场时间：</div>
									<div class="flex-1">{{ carDes.planTime || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">预计回场：</div>
									<div class="flex-1">{{ carDes.returnTime | filterTime }}</div>
								</li>
								<li class="flex">
									<div class="label">电量：</div>
									<div class="flex-1">
										<span class="green-text">{{ carDes.yztGb32960HistoryVo?.ovInfo?.soc || "-" }}%</span>
									</div>
								</li>
								<li class="flex"><div style="visibility: hidden">xxxxx</div></li>
								<li class="flex">
									<div class="label">上一站：</div>
									<div class="flex-1">{{ carDes.frontSiteNa || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">下一站：</div>
									<div class="flex-1">{{ carDes.endSiteNa || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">预计到达时间：</div>
									<div class="flex-1">{{ carDes.planReachTime | filterTime }}</div>
								</li>
								<li class="flex">
									<div class="label">趟次完成率：</div>
									<div class="flex-1">{{ `${carDes.completionRate}%` || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">正点率：</div>
									<div class="flex-1">{{ `${carDes.punctualityRate}%` || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">当日客流：</div>
									<div class="flex-1">{{ carDes.dailyPassengerNum || "-" }}</div>
								</li>
								<li class="flex">
									<div class="label">当前客流：</div>
									<div class="flex-1">{{ carDes.currentPassengerNum || "-" }}</div>
								</li>
							</ul>
						</div>
						<task-info :vehicleId="vehicleId" />
					</div>
					<div class="opt">
						<el-button type="primary" size="small" @click="openDialog('ele')">电子地图</el-button>
						<el-button type="primary" size="small" @click="openDialog('track')">轨迹回放</el-button>
						<el-button type="primary" size="small" @click="openDialog('video')">视频监控</el-button>
						<el-button type="primary" size="small" @click="openDialog('text')">消息通知</el-button>
						<el-button type="primary" size="small" @click="openDialog('dms')">报警信息</el-button>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="车辆资源" name="list"> <car-list :vehicleId="vehicleId"></car-list> </el-tab-pane>
		</el-tabs>
		<el-dialog :title="dialogInfo.dialogTitle" :modal-append-to-body="false" :visible.sync="dialogInfo.showDialog">
			<div class="content-wrap">
				<ele-map
					:coordinate="{}"
					:route-id="routeId"
					v-if="dialogInfo.currentComp === 'ele'"
					:showDialog="dialogInfo.showDialog"
				></ele-map>
				<track-component
					v-if="dialogInfo.currentComp === 'track'"
					:vehicle-id="vehicleId"
					ref="track"
					:showDialog="dialogInfo.showDialog"
				/>
				<video-component
					v-if="dialogInfo.currentComp === 'video'"
					ref="video"
					:params="{ vheicleNo: carDes.customNo, deviceId: carDes.deviceId }"
					:showDialog="dialogInfo.showDialog"
				/>
				<dms-info
					v-if="dialogInfo.currentComp === 'dms'"
					:vheicleNo="carDes.customNo"
					ref="alarm"
					:showDialog="dialogInfo.showDialog"
				/>
				<div class="text-wrap" v-if="dialogInfo.currentComp === 'text'">
					<div>
						<el-input type="textarea" :rows="2" placeholder="请输入文本内容" v-model="diaObj.message"></el-input>
					</div>
					<div class="btn-wrap" slot="footer">
						<el-button @click="dialogInfo.showDialog = false">取消</el-button>
						<el-button type="primary" @click="save">确定</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import EleMap from "../../components/eleMap/index.vue";
import TrackComponent from "../track/index.vue";
import videoComponent from "../video/index.vue";
import TaskInfo from "./components/taskInfo.vue";
import CarList from "./components/carList.vue";
import DmsInfo from "../dms/index.vue";
import dayjs from "dayjs";
export default {
	name: "",
	props: {
		vehicleId: {
			type: String,
			required: true,
			default() {
				return "";
			}
		},
		routeId: {
			type: String,
			required: true,
			default: ""
		},
		dialogVisible: {
			type: Boolean,
			default: false
		}
	},
	filters: {
		filterTime(val) {
			if (val) {
				return dayjs().add(val, "s").format("HH: mm");
			}
			return "-";
		}
	},
	watch: {
		dialogVisible: {
			handler(val) {
				console.log(val, "----------val");
				this.activeName = "cartInfo";
				// this.vehicleId = val;
				val && this.init();
			},
			immediate: true
		}
	},
	components: { EleMap, TrackComponent, videoComponent, TaskInfo, CarList, DmsInfo },
	data() {
		return {
			activeName: "cartInfo",
			current: "today",
			carDes: {},
			dialogInfo: {
				showDialog: false,
				currentComp: "",
				dialogTitle: ""
			},
			diaObj: { message: "" }
		};
	},
	methods: {
		init() {
			const url = "/crrc-palm-bus/realtimeOperation/getVehicleDetails";
			const params = {
				vehicleId: this.vehicleId
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.carDes = res;
				console.log("车辆信息----接口", this.carDes);
			});
		},
		save() {
			if (!this.diaObj.message) {
				this.$message({
					type: "warning",
					message: "请输入文本内容"
				});
				return false;
			}
			let url = "/waybillPlan/textSend";
			let option = {
				...this.diaObj,
				vehicleId: this.vehicleId
			};
			this.$http.post(url, option).then(() => {
				this.dialogVisible = false;
				this.$message({
					type: "success",
					message: "下发成功!"
				});
				this.dialogInfo.showDialog = false;
				this.diaObj = {};
			});
		},
		openDialog(type) {
			switch (type) {
				case "ele":
					this.dialogInfo = { showDialog: true, dialogTitle: "电子地图", currentComp: "ele" };
					break;
				case "track":
					this.dialogInfo = { showDialog: true, dialogTitle: "轨迹回放", currentComp: "track" };
					break;
				case "video":
					// this.$ref.video.init(deviceId, this.carDes.vheicleNo);
					this.dialogInfo = { showDialog: true, dialogTitle: "视频监控", currentComp: "video" };
					break;
				case "text":
					this.dialogInfo = { showDialog: true, dialogTitle: "消息通知", currentComp: "text" };
					break;
				case "dms":
					this.dialogInfo = { showDialog: true, dialogTitle: "报警信息", currentComp: "dms" };
					break;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.car-data {
	padding: 20px;
	::v-deep {
		.el-tabs__nav {
			border: none;
		}
		.el-tabs--card > .el-tabs__header {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}
		.el-tabs--card {
			.el-tabs__item {
				// border: 1px solid rgba(225, 225, 225, 0.2);
				vertical-align: top;
				border: 1px solid #2d4674;
				border-bottom-color: rgba(225, 225, 225, 0.2);
				margin-left: 12px;
				background: rgba(0, 0, 0, 0.5);
				color: #ffffff;
				&.is-active {
					background: transparent;
					border: 1px solid rgba(225, 225, 225, 0.2);
					border-bottom-color: #2d4674;
				}
				&:first-child {
					margin-left: 0;
				}
			}
		}
		.el-textarea {
			textarea {
				background: #5a7dc3;
				resize: none;
				height: 144px;
				border: none;
			}
		}
	}
	.flex {
		display: flex;
		.flex-1 {
			flex: 1;
		}
	}
	.car-data-current {
		.car-info {
			border-right: 1px solid rgba(255, 255, 255, 0.2);
			.label {
				width: 110px;
				text-align: right;
			}
			li {
				margin-bottom: 10px;
			}
		}
		.blue-text {
			color: #2a73ff;
		}
		.green-text {
			color: #00cb95;
		}
		.opt {
			box-sizing: border-box;
			width: 100px;
			padding-left: 20px;
			::v-deep {
				.el-button {
					margin-bottom: 15px;
					& + button {
						margin-left: 0;
					}
				}
			}
		}
	}
	.text-wrap,
	.btn-wrap {
		width: 100%;
	}
	.btn-wrap {
		text-align: center;
		margin-top: 35px;
	}
	.content-wrap {
		width: 100%;
		display: flex;
		justify-self: center;
	}
}
</style>
