<template>
	<div class="line-map-content">
		<div id="baiduMap" class="map-box"></div>
		<div class="all-line" @click="toggleAllHandle">{{ this.isAll ? "当前线路" : "全部线路" }}</div>
		<div class="tool-area">
			<span class="tool-item" @click="showTrafficStatus">路况查询</span>
		</div>
		<car-info ref="carInfo" />
	</div>
</template>

<script>
// import bMapStyleJson from "@/assets/json/bMapStyle";
import createMap from "@/utils/bMap";
import Station from "@/views/admin/bigScreenNew/line/station";
import CustomPoint from "@/utils/customePoint";
import CarInfo from "./components/carInfo.vue";

export default {
	data() {
		return {
			BMap: null,
			carInfo: {},
			timerTag: null,
			showTraffic: false,
			newMap: null,
			isAll: false
		};
	},
	components: {
		CarInfo
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		},
		isAllLine: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		lineInfo(obj) {
			setTimeout(() => {
				obj.routeId && this.init();
			}, 1000);
		},
		isAllLine(val) {
			if (val) {
				this.$refs.carInfo?.closeDetailPanel();
				this.clearTime();
			} else {
				this.openInter();
			}
		}
	},
	activated() {
		this.isAll = false;
		// 优化 不在可视区内，延后创建地图 防止阻碍其他接口请求速度
		setTimeout(() => {
			this.BMap = createMap("baiduMap", {});
			// this.BMap = new window.BMap.Map("baiduMap");
			this.BMap.addEventListener("moving", () => {
				// 更新窗口位置
				if (this.carInfo.vehicleId) {
					const { lng, lat } = this.carInfo;
					this.updatePanelPosition({ lng, lat });
				}
			});
			this.BMap.addEventListener("zoomend", () => {
				if (this.carInfo.vehicleId) {
					const { lng, lat } = this.carInfo;
					this.updatePanelPosition({ lng, lat });
				}
			});
			this.lineInfo.routeId && this.init();
		}, 1000);
	},
	deactivated() {
		this.$refs.carInfo?.closeDetailPanel();
		this.clearTime();
	},
	methods: {
		async init() {
			const url = "/crrc-palm-bus/gis/line/lineMap";
			let params = this.isAll ? {} : { routeId: this.lineInfo.routeId };
			// 关闭弹出的实时框
			this.$refs.carInfo?.closeDetailPanel();
			this.carInfo = {};
			const map = this.BMap;
			// 显示路况操作按钮
			this.showControls();
			const res = await this.$http.post(url, params, { baseType: "base" });
			if (!this.isAll) {
				map?.clearOverlays();
			}
			this.openInter();
			const { allStationPoint } = this.drawLine(res, "#048CFF");
			// 全部线路的模式下，先清除已经绘制的相同线路
			if (this.isAll) {
				const overlays = map?.getOverlays();
				const routeInfo = this.lineInfo;
				overlays.map(overlaysItem => {
					if (overlaysItem.routeId === routeInfo?.routeId) {
						overlaysItem.setStrokeColor("green");
					} else {
						overlaysItem.setStrokeColor("#048CFF");
					}
				});
			}

			if (!this.isAll) {
				map?.setViewport(allStationPoint);
			}
		},
		openInter() {
			this.clearTime();
			if (this.isAll) {
				// 不传查所有车  不用遍历
				this.getCarInfo("");
				this.timerTag = setInterval(() => {
					this.getCarInfo("");
				}, 5000);
			} else {
				this.getCarInfo(this.lineInfo.routeId);
				this.timerTag = setInterval(() => {
					this.getCarInfo(this.lineInfo.routeId);
				}, 5000);
			}
		},
		// 获取车辆信息
		getCarInfo(routeId) {
			const url = "/crrc-palm-bus/gis/line/realtime";
			this.$http.post(url, { routeId }, { baseType: "base" }).then(res => {
				res.map(item => {
					this.addBusPos(item);
					// 过滤到选中车辆
					if (this.carInfo.vehicleId && this.carInfo.vehicleId === item.vehicleId) {
						const { lng, lat } = item;
						this.updatePanelPosition({ lng, lat });
					}
				});
			});
		},

		// 添加车辆位置标注
		addBusPos(posInfo) {
			const map = this.BMap;
			const pcoord = gcoord.transform([posInfo.lng, posInfo.lat], gcoord.WGS84, gcoord.BD09);
			const bdPoint = new BMapGL.Point(pcoord[0], pcoord[1]);

			const marker = new CustomPoint(bdPoint, { txt: posInfo.vehicleNo, lineName: posInfo.routeName });
			marker.id = posInfo.vehicleId;
			const markers = map.getOverlays();
			for (let i = 0; i < markers.length; i++) {
				if (markers[i].id === posInfo.vehicleId) {
					map?.removeOverlay(markers[i]);
				}
			}
			map?.addOverlay(marker);
			if (marker.id === this.carInfo.vehicleId) {
				marker._div.classList.add("active");
			}
			marker.addEventListener("click", () => {
				this.carInfo = posInfo;
				// 当前点击的车放大1.2倍，其余正常大小
				const carList = Array.apply(null, document.getElementsByClassName("station_mark"));
				carList.forEach(item => {
					item.classList.remove("active");
				});
				marker._div.classList.add("active");
				// 打开车辆弹出框
				this.updatePanelPosition({ lng: posInfo.lng, lat: posInfo.lat });
				this.$refs.carInfo?.openDetailPanel(posInfo.vehicleId);
			});
		},
		// 绘制线路图
		drawLine(routeList, color) {
			if (routeList.length) {
				const map = this.BMap;
				const stationPointList = [];
				const allStationPoint = [];
				routeList.map(item => {
					const { siteVoList } = item;
					const drivingItem = new BMapGL.DrivingRoute(this.BMap);
					const siteList = siteVoList.map((site, idx) => {
						let type = "";
						if (idx === 0) {
							type = "first";
						} else if (idx === siteVoList.length - 1) {
							type = "last";
						}
						const p = gcoord.transform([site.longitude, site.latitude], gcoord.WGS84, gcoord.BD09);
						const station = new BMapGL.Point(p[0], p[1]);
						stationPointList.push(site);
						allStationPoint.push(station);
						const stationMark = new Station(station, { type, txt: site.siteName, index: idx });
						stationMark.id = site.siteId;
						map?.addOverlay(stationMark);
						return station;
					});
					for (let i = 0; i < siteList.length; i++) {
						if (i !== siteList.length - 1) {
							drivingItem?.search(siteList[i], siteList[i + 1]); // 绘制两个点之间的路线
						}
					}
					drivingItem?.setSearchCompleteCallback(() => {
						const pts = drivingItem?.getResults().getPlan(0).getRoute(0).getPath();
						const polyline = new BMapGL.Polyline(pts, {
							strokeColor: color,
							strokeWeight: 5,
							strokeOpacity: 1
						});
						polyline.routeId = item.routeId;
						map?.addOverlay(polyline);
					});
				});
				map.addEventListener("zoomend", () => {
					// 移除站点
					const overlays = map?.getOverlays();
					const zoomLevel = map?.getZoom();
					overlays.map(item => {
						if (item.stationConfig) {
							item.domElement.style.display = zoomLevel > 15 ? "" : "none";
						}
					});
				});
				return { stationPointList, allStationPoint };
			}
		},
		// 切换全部线路和单条线路
		toggleAllHandle() {
			this.isAll = !this.isAll;
			this.init();
		},
		clearTime() {
			this.timerTag && clearInterval(this.timerTag);
			this.timerTag = null;
		},
		// 更新车辆数据panel位置
		updatePanelPosition(position) {
			const map = this.BMap;
			const { lng, lat } = position;
			const pcoord = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
			const bdPoint = new BMapGL.Point(pcoord[0], pcoord[1]);
			const pixelPosition = map?.pointToPixel(bdPoint);
			const { x, y } = pixelPosition;
			this.$refs.carInfo?.updatePos({
				x: x,
				y: y
			});
		},
		showControls() {
			const scaleCtrl = new BMapGL.ScaleControl({
				offset: new BMapGL.Size(20, 20)
			}); // 添加比例尺控件
			this.BMap.addControl(scaleCtrl);
			const zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
			this.BMap.addControl(zoomCtrl);
		},
		showTrafficStatus() {
			this.showTraffic ? this.BMap.setTrafficOff() : this.BMap.setTrafficOn();
			this.showTraffic = !this.showTraffic;
		}
	}
};
</script>

<style lang="scss" scoped>
.line-map-content {
	position: relative;
	.map-box {
		width: 100%;
		height: 500px;
	}
	.all-line {
		position: absolute;
		top: 10px;
		left: 10px;
		padding: 4px 10px;
		border-radius: 3px;
		color: #000;
		border: solid 1px #2a73ff;
		cursor: pointer;
		z-index: 999999999;
	}
	.tool-area {
		position: absolute;
		z-index: 999999999;
		top: 10px;
		right: 10px;
		display: flex;
		.tool-item {
			padding: 4px 10px;
			border-radius: 3px;
			color: #000;
			border: solid 1px #2a73ff;
			cursor: pointer;
		}
	}
}
::v-deep {
	.ra-wrapper {
		z-index: 9999;
	}
	.station_wrap {
		&:nth-child(2n + 1) {
			flex-direction: row-reverse;
			text-align: right;
		}
		.icon {
			width: 27px;
			height: 44px;
			background: url("~@/views/admin/bigScreenNew/line/images/station@2x.png") no-repeat 0 0 / 100% 100% transparent;
			&.first {
				background: url("~@/views/admin/bigScreenNew/line/images/start@2x.png") no-repeat 0 0 / 100% 100% transparent;
			}
			&.last {
				background: url("~@/views/admin/bigScreenNew/line/images/end@2x.png") no-repeat 0 0 / 100% 100% transparent;
			}
			& + div {
				color: #000;
				font-size: 14px;
				margin-left: 10px;
				margin-top: 4px;
				width: 100px;
				height: 22px;
				border-radius: 0 22px 22px 0;
			}
		}
	}
	.flex {
		display: flex;
	}
	.station_mark {
		color: #000;
		&:hover {
			.car_number {
				visibility: visible;
			}
		}
		.car_number {
			width: 136px;
			background: #aee3d1;
			// background: url("~@/assets/bigScreen/car_number.png") no-repeat 0 0 / 100% 100% transparent;
			font-size: 14px;
			padding: 5px 10px;
			// font-weight: 600;
			visibility: hidden;
			text-align: center;
		}
		&.active {
			.car_icon {
				transform: scale(1.3);
			}
		}
		.car_icon {
			width: 62px;
			height: 82px;
			background: url("./images/car_info.png") no-repeat 0 0 /100% 100% transparent;
		}
	}
}
</style>
