export default class CustomPoint extends BMapGL.Overlay {
	constructor(point, stationConfig) {
		super();
		this.point = point;
		this.stationConfig = stationConfig;
	}
	initialize(map) {
		this._map = map;
		const temp = `<div class="station_wrap flex"><div class="icon ${this.stationConfig.type}"></div><div class="txt_wrap">${this.stationConfig.txt}</div></div>`;
		const divFragment = document.createRange().createContextualFragment(temp);
		const div = divFragment.querySelectorAll(".station_wrap")[0];
		map.getPanes().markerPane.appendChild(div);
		this._div = div;
		return div;
	}
	draw() {
		// 根据地理坐标，获取图像像素坐标，并设置给容器
		const position = this._map.pointToOverlayPixel(this.point, {
			useRound: false, // 设置此参数，防止抖动
			fixPosition: true // 覆盖物跨越180时修正位置
		});
		this._div.style.position = "absolute";
		this._div.style.top = `${position.y - this._div.clientHeight}px`;
		if (this.stationConfig.index % 2 === 0) {
			// console.log(this.stationConfig.index, `----双数`, position.x, this.stationConfig.txt);
			// this._div.classList.add("mu");
			this._div.style.left = `${position.x - 122}px`;
		} else {
			// console.log(this.stationConfig.index, "----单数", position.x, this.stationConfig.txt);
			// this._div.classList.add("si");
			this._div.style.left = `${position.x - 15}px`;
		}
	}
}
