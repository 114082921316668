<template>
	<div class="car-table-card">
		<!-- <el-divider class="divider"></el-divider> -->
		<jy-table max-height="360" ref="table" class="panel" :data="alarmList">
			<jy-table-column type="index" label="序号" align="center"></jy-table-column>
			<jy-table-column label="报警类型" prop="alarmType">
				<template v-slot="{ row }">
					{{ alarmTypeMap[row.alarmType] }}
				</template>
			</jy-table-column>
			<jy-table-column prop="alarmBeginTime" label="报警时间"></jy-table-column>
			<jy-table-column :minWidth="120" label="报警名称" prop="alarmName"></jy-table-column>
		</jy-table>
	</div>
</template>

<script>
import dayjs from "dayjs";

export default {
	destroyed() {
		this.alarmList = [];
	},
	props: {
		vheicleNo: { type: String, default: "" },
		driverId: { type: String, default: "" },
		showDialog: {
			type: Boolean,
			default: false
		}
	},

	watch: {
		/* vheicleNo: {
			handler: function (val) {
				this.init("", val);
			},
			immediate: true
		},
		driverId: {
			handler: function (val) {
				this.init(val, "");
			},
			immediate: true
		}, */
		showDialog: {
			async handler(val) {
				val && this.init();
			},
			immediate: true
		}
	},
	methods: {
		navToHandle() {
			// this.$router.push({
			//     name: "intelligent",
			//     params: {
			//         vehicleNo: this.vehicleNo
			//     }
			// });
		},
		formatter(row, column, cellValue) {
			return cellValue.$FixedMacTime().split(" ")[1];
		},

		init() {
			// console.log("DMS", driverId);
			// this.driverId = driverId;
			const url = "/crrc-palm-bus/safetyManagement/list";
			const params = {
				pageNum: 1,
				pageSize: 99,
				alarmBeginTime: dayjs().format("YYYY-MM-DD 00:00:00"),
				alarmEndTime: dayjs().format("YYYY-MM-DD 23:59:59"),
				driverId: this.driverId || "",
				vheicleNo: this.vheicleNo || ""
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.alarmList = res?.records || [];
			});
		}
	},
	data() {
		return {
			alarmList: [],
			alarmTypeMap: {
				1: "DSM报警",
				2: "电子围栏报警",
				3: "车辆报警",
				4: "电池报警"
			}
		};
	}
};
</script>
<style lang="scss" scoped>
.car-table-card {
	width: 100%;
	// padding: 35px;
	// display: flex;
	.title {
		font-size: 14px;
		color: #ffffff;
		line-height: 20px;
		margin-bottom: 12px;
	}
	// .divider {
	// 	margin-top: -30px;
	// }
	/* .panel {
		max-height: 360px;
		overflow-y: auto;
	} */
	.search {
		text-align: center;
	}
}
</style>
