<template>
	<div class="plan-table-content">
		<jy-table ref="jyTable" :data="tableList" max-height="494">
			<jy-table-column type="index" width="60" label="序号" align="center"></jy-table-column>
			<jy-table-column prop="eState" label="运行状态" min-width="100" sortable>
				<template v-slot="{ row }">
					<div class="e-state" :class="`e-state-${isPlan ? 0 : 1}-${row.eState}`">
						<span class="icon"></span>
						<span class="text">{{ statusMap[row.eState] || "-" }}</span>
					</div>
				</template>
			</jy-table-column>
			<jy-table-column prop="routeNa" label="线路" min-width="80" show-overflow-tooltip></jy-table-column>
			<jy-table-column label="类型" min-width="60">
				<template slot-scope="scope">{{ goTypeMap[scope.row.schemeT] || "-" }}</template>
			</jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="100">
				<template v-slot="{ row }">
					<span class="cur-po" @click="showCar(row)">{{ row.vehicleNo }}</span>
				</template>
			</jy-table-column>
			<jy-table-column prop="customNo" label="自编号" min-width="100">
				<template v-slot="{ row }">
					<span class="cur-po" @click="showCar(row)">{{ row.vehicleNo }}</span>
				</template>
			</jy-table-column>
			<jy-table-column label="班次" min-width="80" v-if="isPlan">
				<template slot-scope="scope">{{ schduleTypeMap[scope.row.type] || scope.row.type || "-" }}</template>
			</jy-table-column>
			<jy-table-column prop="driverNa" label="司机名称" min-width="160" show-overflow-tooltip>
				<template v-slot="{ row }">
					<span class="cur-po" @click="showDriver(row)">{{ row.driverNa }}</span>
				</template>
			</jy-table-column>
			<jy-table-column prop="dTime" label="计划发车时间" min-width="120"></jy-table-column>
			<template v-if="isPlan">
				<jy-table-column prop="operate" label="发车通知" min-width="80" fixed="right" align="center">
					<template v-slot="{ row }">
						<span class="tip-icon" :class="row.disabled ? 'not-allow' : ''" @click="sedCar(row)"></span>
					</template>
				</jy-table-column>
				<jy-table-column prop="operate" label="操作" min-width="60" fixed="right">
					<template v-slot="{ row }">
						<el-button class="ope-btn" type="text" @click="editHandle(row)">编辑</el-button>
					</template>
				</jy-table-column>
			</template>
		</jy-table>
		<add-shift ref="npm" @updateList="update"></add-shift>
	</div>
</template>

<script>
import addShift from "@/views/admin/smartSchdule/addShift";

export default {
	components: {
		addShift
	},
	data() {
		return {
			planStatusMap: {
				0: "未发车",
				1: "已发车",
				2: "取消派发"
			},
			actStatusMap: {
				1: "已发车",
				2: "已完成",
				3: "过时未发车",
				4: "异常订单"
			},
			goTypeMap: {
				1: "上行",
				2: "下行"
			},
			schduleTypeMap: {
				1: "普通班",
				2: "加班",
				3: "夜班"
			}
		};
	},
	props: {
		isPlan: {
			type: Boolean,
			default: true
		},
		tableList: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		statusMap() {
			return this.isPlan ? this.planStatusMap : this.actStatusMap;
		}
	},
	methods: {
		sedCar(item) {
			if (item.disabled) {
				return;
			}
			item.disabled = true;
			const url = "/waybillPlan/textSend";
			const params = {
				vehicleId: item.vehicleId,
				message:item.vehicleNo+'，请发车'
			};
			this.$http.post(url, params).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				item.disabled = false;
				this.$emit("reload");
			});
		},
		editHandle(rowData) {
			this.$refs.npm.init(rowData, "update");
		},
		update() {
			this.$emit("reload");
		},
		showCar(obj) {
			this.$emit("show-car", obj);
		},
		showDriver(obj) {
			this.$emit("show-driver", obj);
		}
	}
};
</script>
