<template>
	<div class="tabs">
		<div class="tabs-top">
			<span :class="`${activeName === 'sign' ? 'active' : ''}`" @click="activeName = 'sign'">健康档案</span>
			<span :class="`${activeName === 'plan' ? 'active' : ''}`" @click="activeName = 'plan'">驾驶行为分析</span>
			<span :class="`${activeName === 'task' ? 'active' : ''}`" @click="activeName = 'task'">出勤统计</span>
			<!-- <span>考核统计</span> -->
		</div>
		<div class="tabs-content">
			<div v-show="activeName === 'sign'">
				<!-- 健康档案 -->
				<jy-table ref="jyTable" :data="dataList" max-height="494">
					<jy-table-column prop="routeNa" label="检测内容" min-width="160"></jy-table-column>
					<jy-table-column prop="vehicleNo" label="检测值" min-width="100"></jy-table-column>
					<jy-table-column prop="customNo" label="参考值" min-width="100"></jy-table-column>
					<jy-table-column prop="customNo" label="检测时间" min-width="100"></jy-table-column>
					<template slot="empty">
						<img src="../images/empty.png" alt="" srcset="" style="width: 58px" />
						<div>暂无数据</div>
					</template>
				</jy-table>
			</div>
			<div v-show="activeName === 'plan'">
				<!-- 驾驶行为 -->
				<jy-table ref="jyTable" :data="driving" max-height="494">
					<jy-table-column prop="vehicleDailyMileageValue" label="当天里程" min-width="100"></jy-table-column>
					<jy-table-column prop="vehicleRapidAccelerationCount" label="急加速（次）" min-width="130"></jy-table-column>
					<jy-table-column prop="vehicleRapidDecelerationCount" label="急减速（次）" min-width="130"></jy-table-column>
					<jy-table-column prop="vehicleDailyScoreValue" label="评分" min-width="80"></jy-table-column>
					<template slot="empty">
						<img src="../images/empty.png" alt="" srcset="" style="width: 58px" />
						<div>暂无数据</div>
					</template>
				</jy-table>
			</div>
			<div v-show="activeName === 'task'">
				<!-- 出勤统计 -->
				<jy-table ref="jyTable" :data="dataList" max-height="494">
					<jy-table-column prop="routeNa" label="月上班天数" min-width="160"></jy-table-column>
					<jy-table-column prop="vehicleNo" label="当月轮休天数" min-width="150"></jy-table-column>
					<jy-table-column prop="customNo" label="当月执行班次（次）" min-width="150"></jy-table-column>
					<template slot="empty">
						<img src="../images/empty.png" alt="" srcset="" style="width: 58px" />
						<div>暂无数据</div>
					</template>
				</jy-table>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "taskInfo",
	props: { idString: { type: String, required: true, default: "" } },
	data() {
		return {
			activeName: "sign",
			dataList: [],
			driving: []
		};
	},
	watch: {
		idString: function (val) {
			this.initData(val);
		}
	},
	mounted() {
		this.initData(this.idString);
	},
	methods: {
		initData(idString) {
			this.$http.post("/crrc-palm-bus/realtimeOperation/getDriverBehavioral", { idString }, { baseType: "base" }).then(res => {
				this.driving = res;
			});
			// /realtimeOperation/getDriverBehavioral
		}
	}
};
</script>
<style lang="scss" scoped>
.tabs {
	padding: 15px;
	&-top {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		padding-bottom: 13px;
		span {
			display: inline-block;
			cursor: pointer;
			padding: 0 12px;
			&.active {
				border-bottom: 3px solid #2a73ff;
				padding-bottom: 13px;
				margin-bottom: -13px;
			}
		}
	}
	&-content {
		padding: 13px 0;
	}
}
</style>
