<template>
	<div class="line-move-page">
		<div class="line-title">
			<div class="title-item">
				<span class="icon"></span>
				<span class="text">{{ lineData.routeName }}</span>
			</div>
			<div class="title-item">
				<span class="text">计划班次</span>
				<span class="value">{{ passageData?.planWaybillNum || 0 }}</span>
			</div>
			<div class="title-item">
				<span class="text">未发班次</span>
				<span class="value">{{ passageData?.noWaybillNum || 0 }}</span>
			</div>
			<div class="title-item">
				<span class="text">准点率</span>
				<span class="value">{{ passageData?.punctualityRate || 0 }}%</span>
			</div>
			<div class="title-item">
				<span class="text">当日客流</span>
				<span class="value">{{ passageData?.dailyPassengerNum || 0 }}</span>
			</div>
			<div class="title-item">
				<span class="text">当前客流</span>
				<span class="value">{{ passageData?.currentPassengerNum || 0 }}</span>
			</div>
		</div>
		<div class="title-area flex">
			<div class="title-left flex">
				<div class="total-car left-item flex">
					<span class="icon"></span>
					<span class="text">总车数</span>
					<span class="value">{{ lineData.vehicleTotal || 0 }}</span>
				</div>
				<div class="work-item left-item flex">
					<span class="icon"></span>
					<span class="text">排班</span>
					<span class="value">{{ lineData.planVehicleNum || 0 }}</span>
				</div>
				<div class="online-item left-item flex">
					<span class="icon"></span>
					<span class="text">在线</span>
					<span class="value">{{ lineData.onlineVehicleNum || 0 }}</span>
				</div>
				<div class="not-work left-item flex">
					<span class="text">非运营</span>
					<span class="value">{{ lineData.noPlanVehicleNum || 0 }}</span>
				</div>
				<div class="not-work left-item flex">
					<span class="text">跨线车辆</span>
					<span class="value">{{ lineData.overlineVehicleNum || 0 }}</span>
				</div>
			</div>
			<div class="title-right flex">
				<div class="change-icon flex">
					<span class="text">切换车辆图标</span>
					<span class="icon-text flex" :class="isLargeCarIcon ? 'active' : ''" @click="isLargeCarIcon = true">
						<span class="icon"></span>
						<span class="large">大</span>
					</span>
					<span class="icon-text flex" :class="isLargeCarIcon ? '' : 'active'" @click="isLargeCarIcon = false">
						<span class="icon"></span>
						<span class="small">小</span>
					</span>
				</div>
				<div class="driver-num flex">
					<span class="icon"></span>
					<span class="text">司机数</span>
					<span class="value">{{ lineData.driverNum || 0 }}</span>
				</div>
				<!-- <div class="is-work flex" :class="isSign ? 'active' : ''">
					<span class="icon"></span>
					<span class="text">已签到</span>
				</div>
				<div class="no-work flex" :class="isSign ? '' : 'active'">
					<span class="icon"></span>
					<span class="text">未签到/签退</span>
				</div> -->
				<div class="no-driver flex">
					<span class="text">跨线司机</span>
					<span class="value">{{ lineData.overlineDriverNum || 0 }}</span>
				</div>
			</div>
		</div>
		<div class="content-area">
			<!-- <div class="left-content">
				<slot name="left"></slot>
			</div> -->
			<div class="center-content flex">
				<div class="line-map flex">
					<!-- <div class="map-bg" :style="mapBgStyle"></div> -->
					<div class="up-list flex" id="upList" v-show="upStationList.length">
						<div class="left-top">
							<div class="img"></div>
							<div class="text">
								<p>上</p>
								<p>行</p>
							</div>
						</div>
						<div class="right-top">
							<div class="img"></div>
						</div>
						<div class="up-item flex" v-for="(item, index) in upStationList" :key="index">
							<div class="left flex" @click="openStationInfo({ ...item, routeId: lineInfo.routeId })">
								<p class="station-index">{{ index + 1 }}</p>
								<p class="station-name">{{ item.siteName }}</p>
							</div>
							<div class="right" v-if="index !== upStationList.length - 1">
								<template v-if="item.isCurrent">
									<template v-if="isLargeCarIcon">
										<span class="ve-no">{{ item.vehicleNo }}</span>
										<span class="car-icon" :class="`car-icon-${item.carState}`" @click="openCarInfo(item)"></span>
									</template>
									<template v-else>
										<span class="small-icon" @click="openCarInfo(item)"></span>
									</template>
								</template>
								<p class="line" :class="`line-c-${item.traffic}`" :style="getStyle(upStationList)"></p>
							</div>
						</div>
					</div>
					<div class="down-list flex" v-show="downStationList.length">
						<div class="left-bottom">
							<div class="img"></div>
						</div>
						<div class="right-bottom">
							<div class="text">
								<p>下</p>
								<p>行</p>
							</div>
							<div class="img"></div>
						</div>
						<div class="up-item flex" v-for="(item, index) in downStationList" :key="index">
							<div class="left flex" @click="openStationInfo({ ...item, routeId: lineInfo.routeId })">
								<span class="station-index blue">{{ index + 1 }}</span>
								<span class="station-name">{{ item.siteName }}</span>
							</div>
							<div class="right" v-if="index !== 0">
								<template v-if="item.isCurrent">
									<template v-if="isLargeCarIcon">
										<span class="ve-no">{{ item.vehicleNo }}</span>
										<span class="car-icon" :class="`car-icon-${item.carState}`" @click="openCarInfo(item)"></span>
									</template>
									<template v-else>
										<span class="small-icon" @click="openCarInfo(item)"></span>
									</template>
								</template>
								<p class="line" :class="`line-c-${item.traffic}`" :style="getStyle(downStationList)"></p>
							</div>
						</div>
					</div>
				</div>
				<div class="center-bottom flex">
					<div class="mark-item normal">
						<span class="icon"></span>
						<span class="text">正常</span>
					</div>
					<div class="mark-item spd">
						<span class="icon"></span>
						<span class="text">超速</span>
					</div>
					<div class="mark-item out">
						<span class="icon"></span>
						<span class="text">偏离</span>
					</div>
					<div class="mark-item out-line">
						<span class="icon"></span>
						<span class="text">离线</span>
					</div>
					<div class="mark-item jam-1">
						<span class="icon"></span>
						<span class="text">严重拥堵</span>
					</div>
					<div class="mark-item jam-2">
						<span class="icon"></span>
						<span class="text">拥堵</span>
					</div>
					<div class="mark-item jam-3">
						<span class="icon"></span>
						<span class="text">缓行</span>
					</div>
					<!-- <div class="mark-item jam-4">
						<span class="icon"></span>
						<span class="text">畅通</span>
					</div>
					<div class="mark-item jam-5">
						<span class="icon"></span>
						<span class="text">未知路况</span>
					</div> -->
					<div class="mark-item jam-6">
						<span class="icon flex">
							<span class="circle"></span>
						</span>
						<span class="text">上行</span>
					</div>
					<div class="mark-item jam-7">
						<span class="icon flex">
							<span class="circle"></span>
						</span>
						<span class="text">下行</span>
					</div>
				</div>
			</div>
			<div class="right-content">
				<slot name="right"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLargeCarIcon: true,
			isSign: true,
			upStationList: [],
			downStationList: [],
			lineData: {},
			mapBgStyle: {},
			passageData: {}
		};
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		lineInfo: {
			handler(obj) {
				obj.routeId && this.init();
			},
			immediate: true
		},
		"lineInfo.passageData": {
			handler(obj) {
				this.passageData = obj;
			}
		}
	},
	/* activated() {
		this.lineInfo.routeId && this.init();
	},
	deactivated() {
		this.clearInter();
	}, */
	methods: {
		init() {
			this.lineData = JSON.parse(JSON.stringify(this.lineInfo));
			const { downSites, upSites, vehicles } = this.lineData;
			this.upStationList = upSites || [];
			this.downStationList = downSites || [];
			this.formatList(vehicles);

			/* this.clearInter();
			const url = `/crrc-palm-bus/realtimeOperation/getRouteDetails`;
			const params = { routeId: this.lineInfo.routeId };
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				const { downSites, upSites, vehicles } = res;
				this.lineData = res || {};
				this.upStationList = upSites || [];
				this.downStationList = downSites || [];
				this.formatList(vehicles);
				// this.getMapBgStyle();
				this.setInter();
			}); */
		},
		formatList(list) {
			/* traffic 路况：0：未知路况、1：畅通、2：缓行、3：拥堵、4：严重拥堵 */
			this.upStationList.forEach((item, index, arr) => {
				// 后端线路两站之间拥堵情况在下一站体现，上行站前端在当前站体现
				if (index < arr.length - 1) {
					item.traffic = arr[index + 1].traffic;
				}
				// 匹配车辆
				const target = list.find(i => i.psiteId == item.siteId);
				if (target) {
					// item = { ...item, isCurrent: true, vehicleNo: target.vehicleNo, vehicleId: target.vehicleId };
					this.$set(item, "isCurrent", true);
					this.$set(item, "vehicleNo", target.vehicleNo);
					this.$set(item, "vehicleId", target.vehicleId);
					this.$set(item, "carState", target.state);
				} else {
					this.$set(item, "isCurrent", false);
				}
			});
			this.downStationList.forEach(item => {
				// 后端线路两站之间拥堵情况在下一站体现
				// 布局原因  下行站体现与后端一致
				/* if (index < arr.length - 1) {
					item.traffic = arr[index + 1].traffic || 0;
				} */
				// 匹配车辆
				const target = list.find(i => i.psiteId == item.siteId);
				if (target) {
					this.$set(item, "isCurrent", true);
					this.$set(item, "vehicleNo", target.vehicleNo);
					this.$set(item, "vehicleId", target.vehicleId);
					this.$set(item, "carState", target.state);
				} else {
					this.$set(item, "isCurrent", false);
				}
			});
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(() => {
				this.init();
			}, 5000);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		},
		getStyle(list) {
			const exitWidth = 1150 - 20 * list.length;
			const res = exitWidth / (list.length - 1);
			return {
				width: `${res}px`
			};
		},
		/* async getMapBgStyle() {
			await this.$nextTick();
			// 40 margin 6 是线高
			const upListHeight = document.getElementById("upList").offsetHeight;
			const width = 950 + 140;
			const height = upListHeight + 40 + 6;
			this.mapBgStyle = {
				width: width + "px",
				height: height + "px",
				borderRadius: height / 2 + "px"
			};
		}, */
		openStationInfo(data) {
			this.$emit("showStationInfo", data);
		},
		openCarInfo(data) {
			this.$emit("showCarInfo", { ...data, routeId: this.lineData.routeId });
		}
	}
};
</script>

<style lang="scss" scoped>
.line-move-page {
	width: 100%;
	padding: 0 10px;
	.flex {
		display: flex;
	}
	.line-title {
		display: flex;
		align-items: center;
		// padding-left: 11px;
		padding-bottom: 3px;
		border-bottom: solid 1px rgba(255, 255, 255, 0.2);
		margin-bottom: 8px;
		.title-item {
			display: flex;
			align-items: center;
			margin-right: 20px;
			.value {
				font-size: 15px;
				font-weight: 600;
				color: #ffffff;
				line-height: 18px;
				margin-left: 10px;
			}
		}
		.icon {
			width: 32px;
			height: 32px;
			background: url("~@/assets/homeNew/index/line_icon.png") no-repeat;
			background-size: 100% 100%;
			margin-right: 1px;
		}
		.text {
			font-size: 13px;
			font-weight: 600;
			color: #ffffff;
			line-height: 18px;
		}
	}
	.title-area {
		align-items: center;
		.title-left {
			align-items: center;
			.left-item {
				margin-right: 20px;
				align-items: center;
				.text {
					font-size: 13px;
					color: #ffffff;
					line-height: 18px;
					margin-right: 10px;
				}
				.value {
					font-size: 15px;
					font-weight: 600;
					color: #ffffff;
					line-height: 21px;
				}
			}
			.total-car {
				.icon {
					width: 34px;
					height: 34px;
					background: url("~@/assets/homeNew/index/total_car_icon.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 1px;
				}
			}
			.work-item {
				.icon {
					width: 20px;
					height: 21px;
					background: url("~@/assets/homeNew/index/work_icon.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 3px;
				}
				.text {
					color: #ff8d09;
				}
			}
			.online-item {
				.icon {
					width: 20px;
					height: 20px;
					background: url("~@/assets/homeNew/index/online_icon.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 3px;
				}
				.text {
					color: #00cb95;
				}
			}
		}
		.title-right {
			margin-left: auto;
			align-items: center;
			.text {
				font-size: 14px;
				color: #ffffff;
				line-height: 18px;
			}
			.value {
				font-size: 15px;
				font-weight: 500;
				color: #ffffff;
				line-height: 21px;
			}
			.change-icon {
				align-items: center;
				.icon-text {
					align-items: center;
					margin-left: 10px;
					color: #fff;
					cursor: pointer;
					.icon {
						width: 14px;
						height: 14px;
						background: url("~@/assets/homeNew/index/check_icon.png") no-repeat;
						background-size: 100% 100%;
						margin-right: 10px;
					}
					&.active {
						color: #6ea0ff;
						.icon {
							background: url("~@/assets/homeNew/index/check_active_icon.png") no-repeat;
							background-size: 100% 100%;
						}
					}
				}
			}
			.driver-num {
				margin-left: 35px;
				align-items: center;
				.icon {
					width: 29px;
					height: 34px;
					background: url("~@/assets/homeNew/index/driver_icon.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 10px;
				}
				.text {
					margin-right: 6px;
				}
			}
			.is-work,
			.no-work {
				align-items: center;
				margin-left: 35px;
				.icon {
					width: 6px;
					height: 6px;
					background: #cccccc;
					border-radius: 50%;
					margin-right: 5px;
				}
				&.active {
					.icon {
						background: #1e91f0;
					}
				}
			}
			.no-work {
				.text {
					color: #cccccc;
				}
			}
			.no-driver {
				align-items: center;
				margin-left: 35px;
				.text {
					margin-right: 10px;
				}
			}
		}
	}
	.content-area {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 20px;
		.left-content,
		.right-content {
			width: 340px;
		}
		.center-content {
			// width: 1102px;
			flex: 1;
			padding: 70px 0 0;
			flex-direction: column;
			align-items: center;
			// margin: 0 100px;
			.line-map {
				position: relative;
				flex-direction: column;
				align-items: center;
				.map-bg {
					position: absolute;
					left: -70px;
					border: 6px solid #233c73;
				}
				.up-list,
				.down-list {
					position: relative;
					align-items: flex-start;
					.left-top,
					.left-bottom,
					.right-top,
					.right-bottom {
						position: absolute;
						.img {
							width: 44px;
							height: 26px;
							background: url("~@/assets/homeNew/index/arrow_icon.png") no-repeat;
							background-size: 100% 100%;
						}
					}
					.left-top {
						left: -56px;
						top: -4px;
						.img {
							margin-left: 9px;
						}
						.text {
							display: flex;
							flex-direction: column;
							justify-content: center;
							width: 24px;
							height: 48px;
							background: #2b8a23;
							border: 1px solid #979797;
							color: #fff;
							text-align: center;
							line-height: 20px;
						}
					}
					.right-top {
						top: 8px;
						right: -60px;
						.img {
							transform: rotate(90deg);
						}
					}
					.left-bottom {
						left: -56px;
						top: -28px;
						.img {
							transform: rotate(-90deg);
						}
					}
					.right-bottom {
						top: -66px;
						right: -56px;
						.img {
							transform: rotate(180deg);
						}
						.text {
							display: flex;
							flex-direction: column;
							justify-content: center;
							width: 24px;
							height: 48px;
							background: #0283eb;
							border: 1px solid #979797;
							color: #fff;
							text-align: center;
							line-height: 20px;
							margin-left: 28px;
						}
					}
					.up-item {
						align-items: flex-start;
						z-index: 9;
						.left {
							flex-direction: column;
							align-items: center;
							.station-index {
								width: 20px;
								height: 20px;
								background: #2c8a24;
								border: 2px solid #ffffff;
								border-radius: 50%;
								font-size: 10px;
								color: #ffffff;
								line-height: 18px;
								text-align: center;
								margin-top: -7px;
								cursor: pointer;
							}
							.station-name {
								font-size: 12px;
								color: #ffffff;
								line-height: 16px;
								writing-mode: vertical-lr;
								text-orientation: upright;
								margin-top: 8px;
								cursor: pointer;
								// flex: 1;
								/* max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap; */
							}
						}
						.right {
							position: relative;
							flex: 1;
							.line {
								height: 3px;
								background: #2b8a23;
								&.line-c-4 {
									background: #ff3141;
								}
								&.line-c-3 {
									background: #ff6430;
								}
								&.line-c-2 {
									background: #fea931;
								}
								&.line-c-0 {
									background: #ccc;
								}
							}
							.car-icon {
								position: absolute;
								left: 50%;
								top: -14px;
								margin-left: -14px;
								width: 28px;
								height: 15px;
								background-image: url("~@/assets/homeNew/index/normal_car_icon.png");
								background-repeat: no-repeat;
								background-size: 100% 100%;
								cursor: pointer;
								&.car-icon-1 {
									background-image: url("~@/assets/homeNew/index/normal_car_icon.png");
								}
								&.car-icon-2 {
									background-image: url("~@/assets/homeNew/index/error_car_icon.png");
								}
								&.car-icon-3 {
									background-image: url("~@/assets/homeNew/index/alram_car_icon.png");
								}
								&.car-icon-4 {
									background-image: url("~@/assets/homeNew/index/outline_car_icon.png");
								}
							}
							.ve-no {
								position: absolute;
								left: 50%;
								top: -40px;
								margin-left: -17px;
								width: 60px;
								height: 26px;
								background: url("~@/assets/homeNew/index/ve_bg_icon.png") no-repeat;
								background-size: 100% 100%;
								font-size: 10px;
								// font-weight: 800;
								color: #000000;
								line-height: 18px;
								text-align: center;
							}
							.small-icon {
								position: absolute;
								left: 50%;
								top: -18px;
								margin-left: -6px;
								width: 13px;
								height: 16px;
								background: url("~@/assets/homeNew/index/mark_icon.png") no-repeat;
								background-size: 100% 100%;
								cursor: pointer;
							}
						}
					}
				}
				.down-list {
					flex-direction: row-reverse;
					margin-top: 90px;
					.up-item {
						.left {
							.station-index {
								background: #0582eb;
							}
						}
						.right {
							.line {
								background: #0582eb;
							}
							/* .car-icon {
								// top: -22px;
								// margin-left: -27px;
								background: url("~@/assets/homeNew/index/normal_car_icon.png") no-repeat;
								background-size: 100% 100%;
							} */
						}
					}
				}
			}
			.center-bottom {
				margin-top: 30px;
				align-items: center;
				padding: 8px 22px;
				background: rgba(35, 60, 115, 0.4);
				border-radius: 4px;
				.mark-item {
					display: flex;
					align-items: center;
					margin-right: 30px;
					&:last-child {
						margin-right: 0;
					}
					.icon {
						margin-right: 7px;
					}
					.text {
						font-size: 12px;
						color: #ffffff;
						line-height: 18px;
					}
				}
				.normal {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/normal_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.spd {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/error_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.out {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/alram_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.out-line {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/outline_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.jam-1 {
					.icon {
						width: 19px;
						height: 3px;
						background: #ff3141;
						border-radius: 2px;
					}
				}
				.jam-2 {
					.icon {
						width: 19px;
						height: 3px;
						background: #ff6430;
						border-radius: 2px;
					}
				}
				.jam-3 {
					.icon {
						width: 19px;
						height: 3px;
						background: #fea931;
						border-radius: 2px;
					}
				}
				.jam-4 {
					.icon {
						width: 19px;
						height: 3px;
						background: #64c15c;
						border-radius: 2px;
					}
				}
				.jam-5 {
					.icon {
						width: 19px;
						height: 3px;
						background: #cccccc;
						border-radius: 2px;
					}
				}
				.jam-6 {
					.icon {
						align-items: center;
						justify-content: center;
						width: 19px;
						height: 3px;
						background: #2b8a23;
						border-radius: 2px;
						.circle {
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: #2b8a23;
						}
					}
				}
				.jam-7 {
					.icon {
						align-items: center;
						justify-content: center;
						width: 19px;
						height: 3px;
						background: #0283eb;
						border-radius: 2px;
						.circle {
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: #0283eb;
						}
					}
				}
			}
		}
	}
}
</style>
