<template>
	<div class="car-info">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane :label="driverDes.driverName" name="cartInfo">
				<div class="car-info-data">
					<div class="content">
						<div class="base-info">
							<div class="base-info-top">
								<ul>
									<li><img src="./images/default.png" alt="" style="width: 120px" /></li>
									<li>姓名：{{ driverDes.driverName }}</li>
									<li>性别：{{ !driverDes.gender ? "-" : driverDes.gender == 0 ? "女" : "男" }}</li>
									<li>年龄：{{ driverDes.age ? driverDes.age : "-" }}</li>
									<li>驾龄：{{ driverDes.driverAge ? driverDes.driverAge : "-" }}-</li>
									<li>电话：{{ driverDes.phone ? driverDes.phone : "-" }}</li>
								</ul>
								<div class="data-rate">
									<ul>
										<li>
											<div class="label">当日趟次完成率：</div>
											<div>{{ driverDes.completionRate }}%</div>
										</li>
										<li>
											<div class="label">当日正点率：</div>
											<div>{{ driverDes.punctualityRate }}%</div>
										</li>
										<li>
											<div class="label">当日异常次数：</div>
											<div>{{ driverDes.errorCount }}</div>
										</li>
										<li>
											<div class="label">出勤状态：</div>
											<div>{{ driverDes.attendanceStatus == 0 ? "异常" : "正常" }}</div>
										</li>
									</ul>
									<div class="current-status">
										<p>
											最近动向：<br />
											{{ driverDes.nextSite }}
										</p>
										<p>
											下一趟计划时间：<br />
											{{ driverDes.nextPlanTime }}
										</p>
									</div>
								</div>
							</div>
							<task-info :info="{ plan: driverDes.planVoList, scheduling: driverDes.scheduling }" />
						</div>
						<div class="analyze-info">
							<div class="title">能力图谱</div>
							<div class="radar-wrap"><div ref="radarRef" class="radar"></div></div>
							<driver-document :id-string="driverId" />
						</div>
					</div>
					<div class="opt-list">
						<el-button type="primary" size="small" @click="showDmsCilck">DMS</el-button>
					</div>
				</div>
			</el-tab-pane>
			<!-- <el-tab-pane label="司乘资源" name="list"> 司乘资源 </el-tab-pane> -->
		</el-tabs>
		<el-dialog :visible.sync="showDms" :modal="false" title="DMS报警信息">
			<dms-info ref="alarm" :driver-id="driverDes.driverId" :showDialog="showDms" />
		</el-dialog>
	</div>
</template>
<script>
import TaskInfo from "./components/taskInfo.vue";
import DriverDocument from "./components/driverDocument.vue";
import DmsInfo from "../dms/index.vue";
import * as echarts from "echarts";
export default {
	name: "CarInfo",
	props: {
		driverId: {
			type: String,
			required: true,
			default() {
				return "";
			}
		},
		dialogVisible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeName: "cartInfo",
			current: "today",
			showDms: false,
			idString: "",
			driverDes: "",
			routeId: ""
		};
	},
	mounted() {
		this.initRadar();
		this.init(this.driverId);
	},
	watch: {
		dialogVisible: {
			async handler(val) {
				if (val) {
					await this.$nextTick();
					this.initRadar();
					this.init(this.driverId);
				}
			},
			immediate: true
		}
	},
	components: { TaskInfo, DriverDocument, DmsInfo },
	methods: {
		initRadar() {
			const charts = echarts.init(this.$refs.radarRef);
			charts.setOption({
				radar: {
					indicator: [
						{ name: "", max: 6500 },
						{ name: "", max: 16000 },
						{ name: "", max: 30000 },
						{ name: "", max: 38000 },
						{ name: "", max: 52000 },
						{ name: "", max: 25000 }
					]
				},
				series: [
					{
						name: "Budget vs spending",
						type: "radar",
						data: []
					}
				]
			});
		},
		async init(driverIdString) {
			this.idString = driverIdString;
			const url = "/crrc-palm-bus/realtimeOperation/getDriverDetail";
			const params = {
				idString: this.idString
			};
			await this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.driverDes = res;
				this.routeId = res.planData ? res.planData[0].routeId : "1";
				console.log("司机信息", this.driverDes);
			});
			// 获取调度任务
			await this.$http.post("/crrc-palm-bus/realtimeOperation/getDriverActualWaybill", params, { baseType: "base" }).then(res => {
				this.driverDes = { ...this.driverDes, scheduling: res };
			});
		},
		showDmsCilck() {
			this.showDms = true;
		}
	}
};
</script>
<style lang="scss" scoped>
.car-info {
	padding: 15px 25px 0 25px;
	width: 850px;
	::v-deep {
		.el-tabs__nav {
			border: none;
		}
		.el-tabs--card > .el-tabs__header {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}
		.el-tabs--card {
			.el-tabs__item {
				border: 1px solid #2a73ff;
				border-radius: 4px 4px 0px 0px;
				margin-left: 12px;
				color: #ffffff;
				&.is-active {
					background: #2a73ff;
					border-bottom-color: rgba(225, 225, 225, 0.2);
				}
				&:first-child {
					margin-left: 0;
					border-left: 1px solid #2a73ff;
				}
			}
		}
	}
	.car-info-data {
		display: flex;
		.opt-list {
			box-sizing: border-box;
			width: 100px;
			padding-left: 18px;
			.el-button {
				margin-bottom: 12px;
				padding: 10px 25px;
				& + .el-button {
					margin-left: 0;
				}
			}
		}
		.content {
			display: flex;
			flex: 1;
			border-right: 1px solid rgba(255, 255, 255, 0.2);
			.base-info {
				flex: 1;
				&-top {
					display: flex;
					& > ul {
						border-right: 1px solid rgba(255, 255, 255, 0.2);
					}
					& > div {
						padding: 0 15px;
					}
					& > * {
						flex: 1;
					}
					img {
						margin-bottom: 33px;
					}
					li {
						margin-bottom: 12px;
					}
					.current-status {
						background: rgba(0, 0, 0, 0.2);
						border-radius: 4px;
						padding: 13px 15px;
						p {
							margin-bottom: 14px;
						}
					}
				}
				.data-rate {
					li {
						display: flex;
						.label {
							width: 120px;
							text-align: right;
						}
					}
				}
			}
			.analyze-info {
				border-left: 1px solid rgba(255, 255, 255, 0.2);
				box-sizing: border-box;
				width: 300px;
				.title {
					text-align: center;
					font-size: 16px;
				}
				.radar-wrap {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.radar {
					width: 200px;
					height: 300px;
				}
			}
		}
	}
}
</style>
