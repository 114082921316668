<template>
	<div class="home-page-new home-page-new-container">
		<div class="page-container">
			<div class="left-content">
				<div class="line-list">
					<div class="line-item" @click="handleClickAll" :class="isAllLine ? 'active' : ''">全部线路</div>
					<div
						class="line-item"
						@click="handleClick(item)"
						:class="item.isCurrent ? 'active' : ''"
						v-for="(item, index) in lineList"
						:key="index"
					>
						{{ item.lineName }}
					</div>
				</div>
			</div>
			<div class="over-flow">
				<div class="right-content">
					<div class="total-info" v-show="isAllLine">
						<div class="total-item">
							<span class="icon plan-icon"></span>
							<div>
								<p class="text">计划班次</p>
								<p class="unit">（次）</p>
							</div>
							<span class="value">{{ totalPassageData.planWaybillNum || 0 }}</span>
						</div>
						<div class="total-item">
							<span class="icon not-go-icon"></span>
							<div>
								<p class="text">未发班次</p>
								<p class="unit">（次）</p>
							</div>
							<span class="value imp">{{ totalPassageData.noWaybillNum || 0 }}</span>
						</div>
						<div class="total-item">
							<span class="icon rate-icon"></span>
							<div>
								<p class="text">准点率</p>
								<p class="unit">（%）</p>
							</div>
							<span class="value">{{ totalPassageData.punctualityRate || 0 }}</span>
						</div>
						<div class="total-item">
							<span class="icon day-icon"></span>
							<div>
								<p class="text">当日客流</p>
								<p class="unit">（人）</p>
							</div>
							<span class="value">{{ totalPassageData.dailyPassengerNum || 0 }}</span>
						</div>
						<div class="total-item">
							<span class="icon current-icon"></span>
							<div>
								<p class="text">当前客流</p>
								<p class="unit">（人）</p>
							</div>
							<span class="value">{{ totalPassageData.currentPassengerNum || 0 }}</span>
						</div>
					</div>
					<div class="main-content" v-for="(item, index) in dataList" :key="index">
						<div class="line-move">
							<LineMove
								:lineInfo="item"
								@showStationInfo="siteId => openCusDialog('station', { ...siteId, title: '站点信息' })"
								@showCarInfo="vin => openCusDialog('car', { ...vin, title: '车辆信息' })"
							>
								<div slot="right">
									<SchdulePlanCard
										:lineInfo="item"
										:list="item.upWaybillList"
										:goType="1"
										:showOn="showUpOn"
										:showIn="showUpIn"
										:isAllLine="isAllLine"
										@toggle-handle="val => toggleHandle(val, 'showUpTable')"
										@showDriverInfo="driverId => openCusDialog('driver', { ...driverId, title: '司乘信息' })"
										@showCarInfo="driverId => openCusDialog('car', { ...driverId, title: '车辆信息' })"
									></SchdulePlanCard>
									<SchdulePlanCard
										:lineInfo="item"
										:list="item.downWaybillList"
										:goType="2"
										:showOn="showDownOn"
										:showIn="showDownIn"
										:isAllLine="isAllLine"
										@toggle-handle="val => toggleHandle(val, 'showDownTable')"
										@showDriverInfo="driverId => openCusDialog('driver', { ...driverId, title: '司乘信息' })"
										@showCarInfo="driverId => openCusDialog('car', { ...driverId, title: '车辆信息' })"
									></SchdulePlanCard>
								</div>
							</LineMove>
						</div>
					</div>
					<div v-show="!isAllLine">
						<div class="schdule-plan">
							<SchdulePlanTable
								:lineInfo="selectedLine"
								@showDriverInfo="driverId => openCusDialog('driver', { ...driverId, title: '司乘信息' })"
								@showCarInfo="driverId => openCusDialog('car', { ...driverId, title: '车辆信息' })"
							></SchdulePlanTable>
						</div>
						<div class="map-content schdule-plan">
							<LineMap :lineInfo="selectedLine" :isAllLine="isAllLine"></LineMap>
						</div>
					</div>
				</div>
			</div>
		</div>
		<dialog-component :title="dialogTitle" :show.sync="dialogVisible">
			<station-info
				:siteId="currentSiteId"
				:schemeT="currentSchemeT"
				:route-id="currentRouteId"
				:dialogVisible="dialogVisible"
				v-if="dialogType === 'station'"
			/>
			<car-info
				:vehicleId="currentVehicleId"
				:driverId="currentDriverId"
				:route-id="currentRouteId"
				:dialogVisible="dialogVisible"
				v-if="dialogType === 'car'"
			/>
			<driver-info :driverId="currentDriverId" v-if="dialogType === 'driver'" :dialogVisible="dialogVisible" />
		</dialog-component>
	</div>
</template>

<script>
import LineMove from "./components/LineMove/index";
import SchdulePlanCard from "./components/SchdulePlanCard/index";
import SchdulePlanTable from "./components/SchdulePlanTable/index";
import DialogComponent from "./components/dialog/index.vue";
import StationInfo from "./components/stationInfo/index.vue";
import CarInfo from "./components/carInfo/indexNew.vue";
import DriverInfo from "./components/driverInfo/index.vue";
import LineMap from "./components/LineMap/index";
import { playAudio } from "./playAudio";
import { mapGetters } from "vuex";
import { wsHeader } from "@/config/wsconfig";

let ws = null;
let heartbeat = 0;

export default {
	data() {
		return {
			dataList: [{}],
			lineList: [],
			showUpTable: false,
			showDownTable: false,
			dialogTitle: "站点信息",
			dialogVisible: false,
			dialogType: "",
			currentSchemeT:"",//当前站点上下行
			currentSiteId: "", // 当前站点ID
			currentDriverId: "", // 当前司机ID
			currentRouteId: "", // 当前线路ID
			showUpOn: true,
			showUpIn: true,
			showDownOn: true,
			showDownIn: true,
			isAllLine: false,
			timer: null,
			selectedLine: {},
			isCreated: true,
			allPlanSchduleList: [],
			allPassageList: [],
			totalPassageData: {},
			activeIndex: 0
		};
	},
	components: {
		LineMove,
		SchdulePlanCard,
		SchdulePlanTable,
		DialogComponent,
		StationInfo,
		CarInfo,
		DriverInfo,
		LineMap
	},
	computed: {
		...mapGetters(["userInfoGet"])
	},
	async created() {
		/* const h = this.$createElement;
		this.$notify({
			title: "提示",
			message: h("p", { class: "tip-content" }, [
				[1, 2, 3].map(item => {
					return h("span", {}, item);
				})
			]),
			position: "bottom-right",
			duration: 0,
			customClass: "o-notify",
			iconClass: "o-title-icon el-icon-message-solid"
		}); */
		this.isCreated = true;
		// 线路数据
		await this.getLineInfo();
		// 详情数据
		this.queryDetailData();
	},
	activated() {
		const { token } = this.userInfoGet;
		ws = new WebSocket(`${wsHeader}//${location.host}/ischedule/dispatch/departureNotice/${token}`);
		ws.onopen = () => {
			ws.send(token);
			heartbeat = setInterval(() => {
				ws.send(token);
			}, 60 * 1000);
		};
		ws.onmessage = async msgInfo => {
			try {
				const info = JSON.parse(msgInfo.data);
				console.log("----语音提示", info);
				console.log("弹框", this.activeIndex);
				if (this.activeIndex >= 3) {
					this.activeIndex = 0;
					this.$notify.closeAll();
				}
				const { noticeData, advanceNoticeData } = info;
				let noticeTxt = "",
					preTxt = "",
					noticeLabelTxt = "",
					preLabelTxt = "";

				if (noticeData.length) {
					this.activeIndex++;
					noticeTxt = noticeData.map(item => {
						return item.vehicleNo;
					});
					noticeLabelTxt = noticeData.map(item => {
						return item.vehicleNo + "：" + item.dTime;
					});
					//弹框
					const h = this.$createElement;
					this.$notify({
						title: "请发车", //立即就走
						message: h("p", { class: "tip-content" }, [
							noticeLabelTxt.map(item => {
								return h("span", {}, item);
							})
						]),
						position: "bottom-right",
						customClass: "o-notify",
						iconClass: "o-title-icon el-icon-message-solid",
						duration: 0
					});
					//语音播报
					noticeTxt.push("place_send");
					playAudio(noticeTxt).catch(() => {
						this.$notify({
							title: "提示",
							message: h("p", { class: "tip-content" }, "点击页面任意地方，继续播放提示"),
							position: "bottom-right",
							iconClass: "o-title-icon el-icon-message-solid",
							customClass: "o-notify"
						});
					});
				}
				if (advanceNoticeData.length) {
					this.activeIndex++;
					preTxt = advanceNoticeData.map(item => {
						return item.vehicleNo;
					});
					preLabelTxt = advanceNoticeData.map(item => {
						return item.vehicleNo + "：" + item.dTime;
					});
					const h = this.$createElement;
					this.$notify({
						title: "准备发车", //2分钟发车
						message: h("p", { class: "tip-content" }, [
							preLabelTxt.map(item => {
								return h("span", {}, item);
							})
						]),
						position: "bottom-right",
						customClass: "o-notify",
						iconClass: "o-title-icon el-icon-message-solid",
						duration: 0
					});
					preTxt.push("pre_send");
					playAudio(preTxt).catch(() => {
						this.$notify({
							title: "提示",
							message: h("p", { class: "tip-content" }, "点击页面任意地方，继续播放提示"),
							position: "bottom-right",
							customClass: "o-notify",
							iconClass: "o-title-icon el-icon-message-solid"
						});
					});
				}
				console.log("收到了websocket消息", noticeData, advanceNoticeData, res);
			} catch (error) {
				console.log("非JSON数据");
			}
		};

		// 不是第一次进来
		if (!this.isCreated) {
			this.init();
		}
	},
	deactivated() {
		this.clearInter();
		if (ws) {
			ws.close();
			clearInterval(heartbeat);
		}
	},
	methods: {
		async getPassageList() {
			const url = `/crrc-palm-bus/realtimeOperation/getRouteStat`;
			const params = this.isAllLine
				? { orgId: this.userInfoGet.orgId }
				: { routeId: this.selectedLine.routeId, orgId: this.userInfoGet.orgId };
			const res = await this.$http.post(url, params, { baseType: "base" });
			this.allPassageList = res || [];
			this.formatPassageData();
		},
		// 将客流信息匹配到对应线路数据中
		formatPassageData() {
			// 如果是全部线路，匹配没有线路ID的拿到所有的数据
			if (this.isAllLine) {
				this.totalPassageData = this.allPassageList.find(i => !i.routeId) || {};
			}
			this.dataList.map(item => {
				const target = this.allPassageList.find(i => i.routeId === item.routeId);
				if (target) {
					this.$set(item, "passageData", target);
					this.$set(item, "passageData", target);
				} else {
					this.$set(item, "passageData", {});
					this.$set(item, "passageData", {});
				}
				return item;
			});
		},
		async queryDetailData() {
			// 详情数据
			await this.init();
			// 排班数据
			// this.getPlanSchduleList();
			// 客流数据
			this.getPassageList();
		},
		async init() {
			this.clearInter();
			const url = `/crrc-palm-bus/realtimeOperation/getRouteDetailList`;
			const params = this.isAllLine
				? { orgId: this.userInfoGet.orgId }
				: { routeId: this.selectedLine.routeId, orgId: this.userInfoGet.orgId };
			const res = await this.$http.post(url, params, { baseType: "base" });
			const data = res || [];
			// 全部线路-单条线路 切换的时候可能定时的请求已经发出，但没有响应
			// 如果上一个请求响应时间慢的话  会覆盖最新的请求结果
			if ((!this.isAllLine && !params.routeId) || (this.isAllLine && params.routeId)) {
				return;
			}
			this.dataList = data;
			// 把拿到的排班数据放进来
			this.formatPlanData(); // 保留不会有闪一下的问题
			this.getPlanSchduleList();
			// 统计数据
			this.formatPassageData();

			this.isCreated = false;
			this.setInter();
		},
		async handleClickAll() {
			this.lineList.forEach(item => {
				item.isCurrent = false;
			});
			this.isAllLine = true;
			this.queryDetailData();
		},
		// 线路对应排班数据
		async getPlanSchduleList() {
			const url = `/crrc-palm-bus/realtimeOperation/getRouteWaybillPlanList`;
			const params = this.isAllLine
				? { orgId: this.userInfoGet.orgId }
				: { routeId: this.selectedLine.routeId, orgId: this.userInfoGet.orgId };
			const res = await this.$http.post(url, params, { baseType: "base" });
			this.allPlanSchduleList = res || [];
			this.formatPlanData();
		},
		// 将排班信息匹配到对应线路数据中
		formatPlanData() {
			this.dataList.map(item => {
				const target = this.allPlanSchduleList.find(i => i.routeId === item.routeId);
				if (target) {
					this.$set(item, "upWaybillList", target.upWaybillList);
					this.$set(item, "downWaybillList", target.downWaybillList);
				} else {
					this.$set(item, "upWaybillList", []);
					this.$set(item, "downWaybillList", []);
				}
				return item;
			});
		},
		async getLineInfo() {
			const url = `/crrc-palm-bus/realtimeOperation/getRouteList`;
			const params = { orgId: this.userInfoGet.orgId };
			const res = await this.$http.post(url, params, { baseType: "base" });
			this.lineList = res || [];
			this.formatData();
		},
		formatData() {
			this.lineList = this.lineList.map((item, index) => {
				this.$set(item, "isCurrent", index === 0 ? true : false);
				return item;
			});
			this.selectedLine = this.lineList[0];
		},
		async handleClick(obj) {
			this.dataList = [{}];
			this.totalPassageData = {};
			this.selectedLine = obj;
			this.isAllLine = false;
			this.lineList.forEach(item => {
				item.isCurrent = item.routeId === obj.routeId;
			});
			this.queryDetailData();
		},
		// 切换上下行表格显示
		toggleHandle(val, key) {
			this[key] = val;
			// 如果是显示
			if (val) {
				// 子表格显示
				if (key === "showUpTable") {
					this.showUpOn = true;
					this.showUpIn = true;
				} else {
					this.showDownOn = true;
					this.showDownIn = true;
				}
			}
		},
		openCusDialog(type, data) {
			switch (type) {
				case "station":
					this.currentSchemeT = data.goType;
					this.currentSiteId = data.siteId;
					this.currentRouteId = data.routeId;
					console.log("站点信息", data);
					break;
				case "driver":
					this.currentDriverId = data.driverId;
					break;
				case "car":
					this.currentVehicleId = data.vehicleId;
					this.currentRouteId = data.routeId;
					console.log("车辆信息", data);
					break;
			}
			this.dialogTitle = data.title;
			this.dialogType = type;
			this.dialogVisible = true;
		},
		// 关闭子表格
		closeHandle(key) {
			this[key] = false;
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(() => {
				this.init();
			}, 5000);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.home-page-new {
	height: 100%;
	width: 100%;
	background: #233c73;
	overflow: auto;
	.page-container {
		display: flex;
		height: 100%;
		align-items: flex-start;
		.left-content {
			width: 190px;
			height: 100%;
			background: #0d1d40;
			border-radius: 0 4px 4px 0;
			.line-list {
				padding: 8px 15px;
				height: 100%;
				overflow: auto;
				.line-item {
					width: 100%;
					height: 32px;
					// background: #1d3b6e;
					border-radius: 4px;
					font-size: 14px;
					color: #ffffff;
					line-height: 32px;
					margin-bottom: 8px;
					text-align: center;
					cursor: pointer;
					&.active {
						background: #1d3b6e;
					}
				}
			}
		}
		.over-flow {
			flex: 1;
			height: 100%;
			padding: 10px;
			overflow: auto;
		}
		.right-content {
			width: 1704px;
			.total-info {
				display: flex;
				align-items: center;
				// padding-left: 11px;
				// background: #091940;
				// border-radius: 4px;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;
				// padding: 10px 20px;
				.total-item {
					display: flex;
					align-items: center;
					// margin-right: 20px;
					width: 334px;
					height: 72px;
					background: url("~@/assets/homeNew/index/card_bg_icon.png") no-repeat;
					background-size: 100% 100%;
					padding: 0 30px 0 28px;
					.icon {
						width: 48px;
						height: 48px;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						margin-right: 10px;
						&.plan-icon {
							background-image: url("~@/assets/homeNew/index/plan_icon.png");
						}
						&.not-go-icon {
							background-image: url("~@/assets/homeNew/index/not_go_icon.png");
						}
						&.rate-icon {
							background-image: url("~@/assets/homeNew/index/rate_icon.png");
						}
						&.day-icon {
							background-image: url("~@/assets/homeNew/index/day_icon.png");
						}
						&.current-icon {
							background-image: url("~@/assets/homeNew/index/current_icon.png");
						}
					}
					.value {
						font-size: 28px;
						font-weight: 600;
						color: #1eff44;
						line-height: 38px;
						margin-left: auto;
						&.imp {
							color: #ff3920;
						}
					}
					.text,
					.unit {
						font-size: 16px;
						font-weight: 600;
						color: #ffffff;
						line-height: 20px;
						letter-spacing: 2px;
					}
					.unit {
						font-size: 12px;
						font-weight: 400;
					}
				}
			}
			.main-content {
				background: #091940;
				border-radius: 4px;
				padding: 14px 10px 20px;
				margin-bottom: 5px;
				&:last-child {
					margin-bottom: 0;
				}
				// overflow: auto;
				.line-title {
					display: flex;
					align-items: center;
					padding-left: 11px;
					padding-bottom: 3px;
					border-bottom: solid 1px rgba(255, 255, 255, 0.2);
					margin-bottom: 8px;
					.icon {
						width: 32px;
						height: 32px;
						background: url("~@/assets/homeNew/index/line_icon.png") no-repeat;
						background-size: 100% 100%;
						margin-right: 1px;
					}
					.text {
						font-size: 13px;
						font-weight: 600;
						color: #ffffff;
						line-height: 18px;
					}
				}
				/* .line-move {
					margin-bottom: 25px;
				} */
				.car-table-list {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					margin-bottom: 20px;
					padding: 0 10px;
					.table-item {
						width: calc((100% - 30px) / 2);
					}
				}
			}
			.schdule-plan {
				background: #091940;
				margin-top: 5px;
				border-radius: 4px;
				&.map-content {
					padding: 10px;
				}
			}
		}
	}
}
</style>
<style lang="scss">
@import url("./reset.scss");
// .home-page-new {

// }
.o-notify {
	width: 298px;
	background: linear-gradient(180deg, #eef7ff 0%, #e8eefe 100%);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid #eceff6;
	border-radius: 4px;
	padding: 10px 16px;
	/* &.right {
		right: 0;
	} */
	.el-notification__group {
		width: 100%;
		margin: 0;
	}
	.el-notification__title {
		font-size: 14px;
		font-weight: 600;
		color: #333333;
		line-height: 16px;
		margin-left: 6px;
	}
	.el-notification__content {
		margin-top: 18px;
		font-size: 12px;
		color: #333333;
		line-height: 16px;
		margin-left: -14px;
		.tip-content {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			span {
				width: 50%;
				margin-bottom: 12px;
			}
		}
	}
	.el-notification__closeBtn {
		right: 14px;
		top: 10px;
		font-size: 14px;
	}
	.o-title-icon {
		font-size: 14px;
		color: #262626;
		width: 14px;
		height: 14px;
		margin-top: 1px;
	}
}
</style>
